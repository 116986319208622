"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _commonStrings = require("./commonStrings");

var STRINGS = {
  ALL_DONE_TITLE: {
    de: 'Smart Gewusst!'
  },
  TO_VIDEO: {
    de: 'Zum Video',
    en: 'Jump to video'
  },
  TO_VOTING: {
    de: 'Zum Voting',
    en: 'Jump to the voting'
  },
  TO_QUIZ: {
    de: 'Zu den Fragen',
    en: 'Jump to the questions'
  },
  JUMP_ALL_READ: {
    de: 'Lerneinheit abgeschlossen.',
    en: 'Learning unit finished.'
  },
  JUMP_NOT_ALL_READ: {
    de: 'Lerneinheit noch nicht abgeschlossen.',
    en: 'Learning unit not finished yet.'
  }
};

function addStringsToGlobalList() {
  if (!_commonStrings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _commonStrings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _commonStrings.getString.apply(void 0, arguments);
};

exports.getString = getString;