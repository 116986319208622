"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.KESI_PATH_DELIMITER = void 0;
var KESI_PATH_DELIMITER = '!';
exports.KESI_PATH_DELIMITER = KESI_PATH_DELIMITER;