"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/classCallCheck"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/defineProperty"));

/**
 * Created by sebastiangraf on 23.05.17.
 */
var CssHelper = function CssHelper() {
  (0, _classCallCheck2["default"])(this, CssHelper);
};

exports["default"] = CssHelper;
(0, _defineProperty2["default"])(CssHelper, "addCssRule", function (selector, rule) {
  var stylesheet = document.styleSheets[document.styleSheets.length - 1]; // const selector = '.mdl-switch.is-checked .mdl-switch__track';
  // const selector2 = '.mdl-switch__thumb';

  var index = -1;

  if (stylesheet.cssRules && stylesheet.cssRules.length > 0) {
    for (var i = 0; i < stylesheet.cssRules.length; i++) {
      if (stylesheet.cssRules[i].selectorText === selector) {
        stylesheet.deleteRule(i);
        index = i;
      }
    }
  }

  if (stylesheet.insertRule) {
    if (index === -1) {
      stylesheet.insertRule(selector + rule, stylesheet.cssRules.length);
    } else {
      stylesheet.insertRule(selector + rule, index);
    }
  } else if (stylesheet.addRule) {
    stylesheet.addRule(selector, rule, -1);
  }
});
(0, _defineProperty2["default"])(CssHelper, "removeCssRule", function (selector, rule) {
  var stylesheet = document.styleSheets[document.styleSheets.length - 1]; // const selector = '.mdl-switch.is-checked .mdl-switch__track';
  // const selector2 = '.mdl-switch__thumb';

  var index = -1;

  if (stylesheet.cssRules && stylesheet.cssRules.length > 0) {
    for (var i = 0; i < stylesheet.cssRules.length; i++) {
      if (stylesheet.cssRules[i].selectorText === selector) {
        stylesheet.deleteRule(i);
        index = i;
      }
    }
  }
});