"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  CONNECT_CONNECTION_CODE: {
    de: 'Verbindungscode'
  },
  QDCONNECT_COPY_TEXT: {
    de: 'Kopieren Sie diesen Code und geben Sie ihn in der QD app ein um die Applikationen zu verbinden.'
  },
  CONNECT_COPY_TITLE: {
    de: 'Ihr Verbindungscode'
  },
  CONNECT_CANCEL_CONNECTION: {
    de: 'Aufheben'
  },
  CONNECT_CANCEL_TEXT: {
    de: 'Sind Sie sicher, dass Sie die Verbindung aufheben wollen?'
  },
  CONNECT_CANCEL_TITLE: {
    de: 'Verbindung aufheben'
  },
  CONNECT_OFFLINE: {
    de: 'Sie sind offline'
  },
  QDCONNECT_GO_ONLINE: {
    de: 'Bitte gehen Sie online um QD Connect zu benutzen'
  },
  CONNECT_INSERT_ERROR: {
    de: 'Falscher Verbindungscode'
  },
  CONNECT_NO_CODE_YET: {
    de: 'Sie haben noch keinen Code?'
  },
  CONNECT_CONNECTIONS: {
    de: 'Ihre Verbindungen'
  },
  CONNECT_CANCEL: {
    de: 'Verbindung aufheben'
  },
  CONNECT_LOADING: {
    de: 'Lade Daten vom Server'
  },
  QDCONNECT_TITLE: {
    de: 'QD Connect'
  },
  QDCONNECT_EXPLANATION_HEADER: {
    de: 'Was ist QD connect?'
  },
  QDCONNECT_EXPLANATION_TEXT: {
    de: 'Willkommen zu QD connect, hier können Sie die Kesi-Applikation mit dem QDQuiz verbinden, um exklusive Schulungen zu erhalten und den nachhaltigen Lerneffekt zu steigern.'
  },
  CONNECT_INSERT_TEXT: {
    de: 'Bitte geben Sie den Verbindungscode ein.'
  },
  CONNECT_INSERT_TITLE: {
    de: 'Applikationen verbinden'
  },
  KESICONNECT_GO_ONLINE: {
    de: 'Bitte gehen Sie online um Kesi Connect zu benutzen'
  },
  KESICONNECT_CODE_IS: {
    de: 'Ihr code lautet:'
  },
  CONNECT_CODE_ALREADY: {
    de: 'Sie haben schon einen code?'
  },
  KESICONNECT_EXPLANATION_TEXT: {
    de: 'Willkommen zu Kesi Connect, hier können Sie das QDQuiz mit der Kesi-Applikation verbinden, um exklusive Schulungen zu erhalten und den nachhaltigen Lerneffekt zu steigern.'
  },
  KESICONNECT_EXPLANATION_HEADER: {
    de: 'Was ist Kesi connect?'
  },
  KESICONNECT_SUCCESSFUL_TEXT: {
    de: 'EqueoQD und KESI erfolgreich verbunden'
  },
  KESICONNECT_COPY_TEXT: {
    de: 'Kopieren Sie diesen Code und geben Sie ihn in der KESI app ein um die Applikationen zu verbinden.'
  },
  KESICONNECT_TITLE: {
    de: 'Kesi Connect'
  },
  QDCONNECT_SUCCESSFUL_TEXT: {
    de: 'KESI und EqueoQD erfolgreich verbunden'
  },
  CONNECT_SUCCESSFUL_ACTIVATED: {
    de: 'Erfolgreich verbunden'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;