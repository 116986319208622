"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../config/strings");

var STRINGS = {
  SUCCESSFULLY_ACTIVATED_MESSAGE: {
    de: 'Die Aktivierung war erfolgreich.'
  },
  SUCCESSFULLY_ACTIVATED_TITLE: {
    de: 'Funktion freigeschaltet'
  },
  ERROR_ACTIVATION_WRONG_FEATURE: {
    de: 'Der Code war leider falsch.'
  },
  FEATURE_ALREADY_ACTIVATED_MESSAGE: {
    de: 'Diese Funktion ist auf Ihrem Gerät bereits freigeschaltet.'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;