"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  QR_CODE_HEADLINE: {
    de: 'Login mit QR-Code',
    en: 'Login with QR Code'
  },
  QR_CODE_INFOTEXT: {
    de: 'Loggen Sie sich automatisch auf dem Mobiltelefon ein, in dem Sie den QR Code mit der App scannen.',
    en: 'Sign in automatically with your mobile phone. Just scan the QR code qith your app.'
  },
  QR_CODE_PROGRESSTEXT: {
    de: 'QR Code läuft ab in:',
    en: 'QR code is invalid in:'
  },
  QR_CODE_PROGRESS_UNIT: {
    de: 'Minuten',
    en: 'minutes'
  },
  QR_CODE_REFRESH: {
    de: 'QR Code aktualisieren',
    en: 'Refresh QR code'
  },
  QR_CODE_ERROR_LOAD_REFERRER: {
    de: 'Error: Ihre Login Informationen konnten nicht geladen werden',
    en: 'Error: Could not load your login information'
  },
  QR_CODE_ERROR_LOAD_CODE: {
    de: 'Error: QR Code konnte nicht geladen werden',
    en: 'Error: Could not load QR code'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;