"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  ENTER_TOURNAMENT_CODE: {
    de: 'Gib hier den Turniernamen ein'
  },
  TOURNAMENT_CODE_LABEL: {
    de: 'Turniercode'
  },
  TIE_NOBREAK: {
    de: 'Unentschieden!'
  },
  TOURNAMENT_LEAVE: {
    de: 'Turnier verlassen'
  },
  TOURNAMENT_MEMBER_AMOUNT_TEAM: {
    de: 'Team Mitglieder'
  },
  CHOOSE_TEAM: {
    de: 'Bitte wählen Sie Ihr Team aus'
  },
  WAITING_FOR_TOURNAMENT_START: {
    de: 'Warte auf Turnierstart '
  },
  WAITING_FOR_TEAM_CHOICE: {
    de: 'Warte auf Teamauswahl'
  },
  GO_ON: {
    de: 'Weiter'
  },
  PLAY_NOW_TITLE: {
    de: 'Weiter geht´s!'
  },
  PLAY_NOW_TEXT: {
    de: 'Spiele jetzt gegen <b>$0</b>'
  },
  PLAY_NOW_TEXT_NO_USERNAME: {
    de: 'Bitte wähle eine Kategorie. Dein Gegner wartet!'
  },
  PLAY_NOW_TEXT_YOUR_TURN: {
    de: 'Du bist an der Reihe. Bitte spiel weiter!'
  },
  WAIT_FOR_OPPONENT_TITLE: {
    de: 'Bitte warte kurz.'
  },
  WAIT_FOR_OPPONENT_TEXT: {
    de: 'Deine Gegner überlegen noch.'
  },
  TOURNAMENT_FINISHED_TITLE: {
    de: 'Du hast es geschafft!'
  },
  TOURNAMENT_FINISHED_TEXT: {
    de: 'Warte auf das Turnierende.'
  },
  TOURNAMENT_FINISHED_TEXT_NOT_FINISHED: {
    de: 'Das Turnier geht zu Ende, deswegen kannst du ab jetzt keine weiteren Spieler herausfordern.'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;