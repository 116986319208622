"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/typeof"));

var _picolog = _interopRequireDefault(require("picolog"));

var _BuildConstants = require("../utils/BuildConstants");

var _LogService = require("../KESI2/Services/LogService");

var _StringHelper = _interopRequireDefault(require("./StringHelper"));

if ((typeof console === "undefined" ? "undefined" : (0, _typeof2["default"])(console)) === 'object') {
  window.console = _picolog["default"];

  if (window.console !== _picolog["default"]) {
    _picolog["default"].log('Failed to replace the console with picolog');
  }
}

if (_BuildConstants.NODE_ENV === 'production') {
  _picolog["default"].level = _picolog["default"].ERROR; // hooking

  console.error = function () {
    for (var _len = arguments.length, error = new Array(_len), _key = 0; _key < _len; _key++) {
      error[_key] = arguments[_key];
    }

    (0, _LogService.pushErrorMessage)(null, _StringHelper["default"].stringifyAnything(error));
  };
} else {
  _picolog["default"].level = _picolog["default"].TRACE;
}

var _default = _picolog["default"];
exports["default"] = _default;