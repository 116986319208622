"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.decryptAES = exports.encryptAES = void 0;

var CryptoJS = require('crypto-js');

var encryptAES = function encryptAES(key, plainText) {
  return CryptoJS.AES.encrypt(plainText, key);
};

exports.encryptAES = encryptAES;

var decryptAES = function decryptAES(key, cipherText) {
  var bytes = CryptoJS.AES.decrypt(cipherText, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

exports.decryptAES = decryptAES;