"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _reflux = _interopRequireDefault(require("reflux"));

// noinspection Eslint
// eslint-disable-line
function ColorActions() {
  return _reflux["default"].createActions(['addTheme', 'resetTheme']);
} // noinspection Eslint
//module.exports = window.QuizActions = new ColorActions(); // eslint-disable-line


var actions = new ColorActions();
var _default = actions;
exports["default"] = _default;