"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _commonStrings = require("./commonStrings");

var STRINGS = {
  LECTURE_COUNT: {
    de: 'Einheiten',
    en: 'Courses',
    it: 'Corsi',
    fr: 'Cours',
    es: 'Cursos'
  },
  LECTURE_COUNT_ONE: {
    de: 'Einheit',
    en: 'Course',
    it: 'Corso',
    fr: 'Cour',
    es: 'Curso'
  },
  LECTURE_TIME: {
    de: 'Zeit',
    en: 'Time',
    it: 'Ora',
    fr: 'Heure',
    es: 'Tiempo'
  },
  CONTENT_NOT_FOUND: {
    de: 'Inhalt nicht verfügbar',
    en: 'Content not available'
  },
  CONTENT_NOT_FOUND_MESSAGE: {
    de: 'Leider ist der gewünschte Inhalt zurzeit nicht verfügbar. Bitte versuchen Sie es später noch einmal oder überprüfen Sie Ihre Internetverbindung.',
    en: 'Unfortunately, the requested content is currently not available. Please try again later or check your internet connection.'
  },
  NO_FAVS: {
    de: 'Du hast in diesem Thema noch keine Favoriten ausgewählt. Klicke oben rechts auf die Fahne, um den Filter zu deaktivieren und alle Kacheln anzuzeigen.'
  }
};

function addStringsToGlobalList() {
  if (!_commonStrings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _commonStrings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _commonStrings.getString.apply(void 0, arguments);
};

exports.getString = getString;