"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getStyles = void 0;

var _ColorHelper = require("../../../../helpers/ColorHelper");

var _ObjectHelper = require("../../../../helpers/ObjectHelper");

var getStyles = function getStyles(componentState, componentProps) {
  var theme = null;

  if (componentState.quiz && componentState.currentTheme in componentState.quiz) {
    theme = componentState.quiz[componentState.currentTheme];
  }

  var defaultTheme = componentState.quiz ? componentState.quiz["default"] : null;
  var outerStyle = {};

  if (componentProps && componentProps.hidden) {
    outerStyle.display = 'none';
  } //  console.log('QuizHomeStyle', defaultTheme, theme, getObjectValueWithFallbacks(theme, 'homeTextColor', defaultTheme, 'homeTextColor', COLOR.BLACK))


  var groupStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.BLACK)
  };
  var homeStyle = {
    background: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'homeBackground', defaultTheme, 'homeBackground', _ColorHelper.COLOR.BLACK),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'homeTextColor', defaultTheme, 'homeTextColor', _ColorHelper.COLOR.WHITE)
  };
  var listStyle = {
    background: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizBackground', defaultTheme, 'quizBackground', _ColorHelper.COLOR.WHITE),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'mainTextColor', defaultTheme, 'mainTextColor', _ColorHelper.COLOR.BLACK)
  };
  var listItemStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'mainTextColor', defaultTheme, 'mainTextColor', _ColorHelper.COLOR.BLACK)
  };
  var tabStyle = {
    background: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'homeTabBackground', defaultTheme, 'homeTabBackground', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'homeBackground', defaultTheme, 'homeBackground', _ColorHelper.COLOR.BLACK)),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'tabTextColor', defaultTheme, 'tabTextColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'homeTextColor', defaultTheme, 'homeTextColor', _ColorHelper.COLOR.BLACK))
  };
  var tabStyleActive = {
    // backgroundColor: getObjectValueWithFallbacks(theme, 'quizThirdColor', defaultTheme, 'quizThirdColor', COLOR.WHITE),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'tabTextColor', defaultTheme, 'tabTextColor', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'homeTextColor', defaultTheme, 'homeTextColor', _ColorHelper.COLOR.BLACK)) // borderBottom: ` 3px solid ${getObjectValueWithFallbacks(theme, 'homeTextColor', defaultTheme, 'mainTextColor', COLOR.BLACK)}`

  };
  return {
    outerStyle: outerStyle,
    groupStyle: groupStyle,
    homeStyle: homeStyle,
    listStyle: listStyle,
    listItemStyle: listItemStyle,
    tabStyle: tabStyle,
    tabStyleActive: tabStyleActive
  };
};

exports.getStyles = getStyles;