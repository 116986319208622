"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../../config/strings");

var STRINGS = {
  MC_CORRECT_ANSWER: {
    de: 'Antwort $0 - Richtig! $1<br><br>',
    en: 'Answer $0 - Correct! $1<br><br>'
  },
  MC_CORRECT_ANSWER_DRUPAL_ONLY: {
    de: 'Antwort $0 - $1<br><br>',
    en: 'Answer $0 - $1<br><br>'
  },
  MC_WRONG_ANSWER: {
    de: 'Antwort $0 - Nicht Richtig! $1<br><br>',
    en: 'Answer $0 - Not Correct! $1<br><br>'
  },
  MC_WRONG_ANSWER_DRUPAL_ONLY: {
    de: 'Antwort $0 - $1<br><br>',
    en: 'Answer $0 - $1<br><br>'
  },
  MC_ALL_CORRECT: {
    de: 'Sie haben alle richtigen Antworten ausgewählt<br><br>$0',
    en: 'All correct answers selected<br><br>$0'
  },
  MC_NOT_ENOUGH_CHOSEN: {
    de: 'Sie haben zu wenige Antworten ausgewählt.<br><br>$0',
    en: 'Not enough answers selected<br><br>$0'
  },
  MC_ALL_WRONG: {
    de: 'Alle ausgewählten Antworten waren falsch.<br><br>$0',
    en: 'All selected answers are wrong<br><br>$0'
  },
  MC_NOT_ALL_RIGHT: {
    de: 'Nicht alle ausgewählten Antworten waren richtig.<br><br>$0',
    en: 'Not all selected answers are correct.<br><br>$0'
  },
  MC_TOO_MUCH_CHOSEN: {
    de: 'Sie haben zu viele Antworten ausgewählt.<br><br>$0',
    en: 'Too much answers selected.<br><br>$0'
  },
  MC_TRY_AGAIN: {
    de: 'ERNEUT VERSUCHEN',
    en: 'TRY AGAIN'
  },
  QUESTION_NUMBER: {
    de: 'Frage $0 von $1',
    en: 'Question $0 of $1'
  },
  CORRECT_KESI: {
    de: 'Richtig',
    en: 'Yes!'
  },
  NOT_CORRECT_KESI: {
    de: 'Schade',
    en: 'No'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;