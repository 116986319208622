"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/slicedToArray"));

var _react = require("react");

function useAudioPlayer(props) {
  var _useState = (0, _react.useState)(),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      duration = _useState2[0],
      setDuration = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      curTime = _useState4[0],
      setCurTime = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
      playing = _useState6[0],
      setPlaying = _useState6[1];

  var _useState7 = (0, _react.useState)(),
      _useState8 = (0, _slicedToArray2["default"])(_useState7, 2),
      clickedTime = _useState8[0],
      setClickedTime = _useState8[1];

  (0, _react.useEffect)(function () {
    var audio = props.ref.current;

    if (audio) {
      // state setters wrappers
      var setAudioData = function setAudioData() {
        setDuration(audio.duration);
        setCurTime(audio.currentTime);
      };

      var setAudioTime = function setAudioTime() {
        setCurTime(audio.currentTime);
      }; // DOM listeners: update React state on DOM events


      audio.addEventListener('loadeddata', setAudioData);
      audio.addEventListener('timeupdate', setAudioTime); // React state listeners: update DOM on React state changes

      playing ? audio.play() : audio.pause();

      if (clickedTime && clickedTime !== curTime) {
        console.log('clickedTime changed', clickedTime, curTime);
        audio.currentTime = clickedTime;
        setClickedTime(null);
      } // effect cleanup


      return function () {
        audio.removeEventListener('loadeddata', setAudioData);
        audio.removeEventListener('timeupdate', setAudioTime);
      };
    }
  });
  return {
    curTime: curTime,
    duration: duration,
    playing: playing,
    setPlaying: setPlaying,
    setClickedTime: setClickedTime
  };
}

var _default = useAudioPlayer;
exports["default"] = _default;