"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  MY_PROGRESS: {
    de: 'Mein Fortschritt',
    en: 'My progress'
  },
  USER_INFO_ALL_DONE: {
    de: '<b>Herzlichen Glückwunsch, Du hast alle grundlegenden Kurse abgeschlossen!</b><br/>Hier kannst Du Dir jetzt Deine Teilnahmebestätigung herunterladen.<br/>'
  },
  USER_INFO_CERT_DONE: {
    de: '<b>Herzlichen Glückwunsch, Sie haben den Kurs $0 abgeschlossen!</b><br/>Hier können Sie jetzt Ihr Zertifikat herunterladen.<br/>'
  },
  DOWNLOAD_CERTIFICATE: {
    de: 'Teilnahmebestätigung herunterladen'
  },
  DOWNLOAD_CERTIFICATE_WITH_NAME: {
    de: '$0 Zertifikat herunterladen'
  },
  LEARN_PROGRESS: {
    de: 'Hier sehen Sie Ihren Bearbeitungsstand.',
    en: 'Here you can see your learning state'
  },
  COURSE_TITLE: {
    de: 'Kurs & Lerneinheiten',
    en: 'Course'
  },
  LEARNED_TITLE: {
    de: 'Bearbeitet',
    en: 'Finished'
  },
  NAME: {
    de: 'Name'
  },
  DEPARTMENT: {
    de: 'Abteilung'
  },
  LOCATION: {
    de: 'Standort'
  },
  PHONE_NUMBER: {
    de: 'Rufnummer'
  },
  NO_NAME: {
    de: 'Kein Name angegeben'
  },
  NO_DEPARTMENT: {
    de: 'Keine Abteilung angegeben'
  },
  NO_LOCATION: {
    de: 'Kein Standort angegeben'
  },
  NO_PHONE_NUMBER: {
    de: 'Keine Rufnummer angegeben'
  },
  FIRST_NAME: {
    de: 'Vorname'
  },
  LAST_NAME: {
    de: 'Nachname'
  },
  CHANGE_USERDATA_TITLE: {
    de: 'Bitte geben Sie Ihre Daten ein'
  },
  PLEASE_SELECT: {
    de: 'Bitte wählen'
  },
  MR: {
    de: 'Herr'
  },
  MRS: {
    de: 'Frau'
  },
  SALUTATION: {
    de: 'Anrede'
  },
  NO_SALUTATION: {
    de: 'Keine Anrede angegeben'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;