"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.openICS = exports.openPDF = void 0;

var openPDF = function openPDF(fileName) {
  console.log('openPDF', fileName);

  if (fileName) {
    if (window.cordova && window.cordova.plugins && window.cordova.plugins.fileOpener2) {
      window.cordova.plugins.fileOpener2.open(fileName, 'application/pdf', {
        error: function error(e) {
          console.log('Error status: ' + e.status + ' - Error message: ' + e.message, e);
        },
        success: function success() {
          console.log('file opened successfully');
        }
      });
    }
  }
};

exports.openPDF = openPDF;

var openICS = function openICS(fileName) {
  console.log('openICS', fileName);

  if (fileName) {
    if (window.cordova && window.cordova.plugins && window.cordova.plugins.fileOpener2) {
      window.cordova.plugins.fileOpener2.open(fileName, 'text/calendar', {
        error: function error(e) {
          console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
        },
        success: function success() {
          console.log('file opened successfully');
        }
      });
    }
  }
};

exports.openICS = openICS;