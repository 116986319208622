"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

function checkConsistency(serverHistory, localHistory) {
  var consistent = true;

  for (var rubric in serverHistory) {
    var rubricServerHistory = serverHistory[rubric];
    var rubricLocalHistory = localHistory[rubric];

    if (!rubricLocalHistory) {
      console.error("History consistency check failed (partial1)", rubricLocalHistory, rubricServerHistory, rubric);
      consistent = false;
      break;
    }

    for (var questionId in rubricServerHistory) {
      if (rubricLocalHistory[questionId]) {
        var val = rubricServerHistory[questionId];
        var val2 = rubricLocalHistory[questionId];

        if (!(val.distance === val2.distance && val.history === val2.history)) {
          console.error("History consistency check failed (partial2) SERVER: ".concat(val.history.length, " CLIENT: ").concat(val2.history.length), val, val2);
          consistent = false;
          break;
        }
      } else {
        console.error("History consistency check failed (partial3)", rubricLocalHistory[questionId], rubricServerHistory[questionId], questionId);
        consistent = false;
        break;
      }
    }
  }

  if (consistent) {
    console.info("History consistency check successful: ", localHistory, serverHistory);
  }
}

var HistoryConsistencyChecker = {
  checkConsistency: checkConsistency
};
var _default = HistoryConsistencyChecker;
exports["default"] = _default;