"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.ABLY_COMMANDS = void 0;
var ABLY_COMMANDS = {
  CANCEL_CONNECTION: 'cancelConnection',
  CONFIRM_CONNECTION: 'confirmConnection',
  REQUEST_CONNECTION: 'requestConnection',
  ERROR_CONNECTION: 'errorConnection',
  ALLOW_PASSWORD_RESET: 'allowPasswordReset',
  LOGGED_OUT: 'loggedout',
  REORDER: 'reorder',
  GAME_FINISHED: 'gameFinished',
  OPPONENT_PLAYED: 'opponentPlayed',
  USER_UPDATE: 'userUpdate',
  GET_ROUND_DATA: 'getRoundData',
  ROUND_DATA: 'roundData',
  PARTNER_ACTIVE: 'partnerActive',
  FEEDBACK_TAGS: 'kesiFeedbackTags',
  FEEDBACK_TAGS_RECEIVED: 'feedbackTagsReceived',
  ROUND_PLAYED: 'roundPlayed',
  TOURNAMENT_UPDATE: 'updateTournament',
  TOURNAMENT_START: 'startTournament',
  PUSH: 'push',
  SYS_MESSAGES: 'sysMessages'
};
exports.ABLY_COMMANDS = ABLY_COMMANDS;