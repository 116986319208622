"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.sim = sim;
var _0x12c9 = ['length', 'push', 'max'];

(function (_0x5df683, _0x305085) {
  var _0x4ba684 = function _0x4ba684(_0x143e9f) {
    while (--_0x143e9f) {
      _0x5df683['push'](_0x5df683['shift']());
    }
  };

  _0x4ba684(++_0x305085);
})(_0x12c9, 0x141);

var _0x1aa5 = function _0x1aa5(_0x487799, _0x377c9c) {
  _0x487799 = _0x487799 - 0x0;
  var _0x36b655 = _0x12c9[_0x487799];
  return _0x36b655;
};

function f1(_0x2bf9ef, _0x46d509) {
  var _0x6824c7 = _0x2bf9ef[_0x1aa5('0x0')];

  var _0x555e99 = _0x46d509[_0x1aa5('0x0')];

  var _0x19456e = [];
  _0x19456e = f2(_0x2bf9ef, _0x46d509);
  return _0x19456e[_0x6824c7][_0x555e99];
}

function f3(_0x5e9f0e, _0x427f23) {
  var _0x3710c2 = _0x5e9f0e['length'];

  var _0xf9b87c = _0x427f23[_0x1aa5('0x0')];

  var _0x4b6c89 = [];

  var _0x31a9cc;

  var _0x54b3c4;

  _0x4b6c89 = f2(_0x5e9f0e, _0x427f23);
  return function f4(_0x31a9cc, _0x54b3c4) {
    if (_0x31a9cc * _0x54b3c4 === 0x0) {
      return '';
    }

    if (_0x5e9f0e[_0x31a9cc - 0x1] === _0x427f23[_0x54b3c4 - 0x1]) {
      return f4(_0x31a9cc - 0x1, _0x54b3c4 - 0x1) + _0x5e9f0e[_0x31a9cc - 0x1];
    }

    return _0x4b6c89[_0x31a9cc][_0x54b3c4 - 0x1] > _0x4b6c89[_0x31a9cc - 0x1][_0x54b3c4] ? f4(_0x31a9cc, _0x54b3c4 - 0x1) : f4(_0x31a9cc - 0x1, _0x54b3c4);
  }(_0x3710c2, _0xf9b87c);
}

function f2(_0x1b6f4c, _0xe5ad8a) {
  var _0x2d2b03 = _0x1b6f4c[_0x1aa5('0x0')];

  var _0xcb45e6 = _0xe5ad8a[_0x1aa5('0x0')];

  var _0x4ba89f = [];

  var _0x311a2f;

  var _0x2b7e7c;

  for (_0x311a2f = 0x0; _0x311a2f <= _0x2d2b03; _0x311a2f++) {
    _0x4ba89f[_0x1aa5('0x1')]([0x0]);
  }

  for (_0x2b7e7c = 0x0; _0x2b7e7c < _0xcb45e6; _0x2b7e7c++) {
    _0x4ba89f[0x0]['push'](0x0);
  }

  for (_0x311a2f = 0x0; _0x311a2f < _0x2d2b03; _0x311a2f++) {
    for (_0x2b7e7c = 0x0; _0x2b7e7c < _0xcb45e6; _0x2b7e7c++) {
      _0x4ba89f[_0x311a2f + 0x1][_0x2b7e7c + 0x1] = _0x1b6f4c[_0x311a2f] === _0xe5ad8a[_0x2b7e7c] ? _0x4ba89f[_0x311a2f][_0x2b7e7c] + 0x1 : Math[_0x1aa5('0x2')](_0x4ba89f[_0x311a2f + 0x1][_0x2b7e7c], _0x4ba89f[_0x311a2f][_0x2b7e7c + 0x1]);
    }
  }

  return _0x4ba89f;
}

function sim(_0x5ea796, _0x34007e) {
  var _0x36920f = _0x5ea796[_0x1aa5('0x0')];

  var _0x53f7dd = _0x34007e[_0x1aa5('0x0')];

  var _0x4bf3c3 = f1(_0x5ea796, _0x34007e);

  return _0x4bf3c3 * 0x2 / (_0x36920f + _0x53f7dd);
}