"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.addStrings = exports.getString = void 0;

var _strings = require("../../../../../config/strings");

var STRINGS = {
  FLIP_MANDATORY_NOT_READ: {
    de: 'Um diesen Abschnitt zu bearbeiten, drehen Sie bitte alle Grafiken per Klick – wie beim Memory Spiel – um, um mehr über das Thema zu erfahren.'
  },
  FLIP_MANDATORY_READ: {
    de: 'Drehen Sie – wie beim Memory spielen – Grafiken per Klick um, um mehr über das Thema zu erfahren.'
  },
  FLIP_NOT_MANDATORY: {
    de: 'Drehen Sie – wie beim Memory spielen – Grafiken per Klick um, um mehr über das Thema zu erfahren. Dieser Abschnitt muss nicht bearbeitet werden, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  FLIP_INFO: {
    de: 'Drehen Sie – wie beim Memory spielen – Grafiken per Klick um, um mehr über das Thema zu erfahren.'
  },
  HTML_MANDATORY_NOT_READ: {
    de: 'Bei diesem Symbol erwartet Sie Text, Bild oder eine Kombination aus beidem. Um diesen Abschnitt zu bearbeiten, sehen Sie sich bitte die Inhalte an.'
  },
  HTML_MANDATORY_READ: {
    de: 'Bei diesem Symbol erwartet Sie Text, Bild oder eine Kombination aus beidem.'
  },
  HTML_NOT_MANDATORY: {
    de: 'Bei diesem Symbol erwartet Sie Text, Bild oder eine Kombination aus beidem. Dieser Abschnitt muss nicht bearbeitet werden, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  HTML_INFO: {
    de: 'Bei diesem Symbol erwartet Sie Text, Bild oder eine Kombination aus beidem.'
  },
  LIST_MANDATORY_NOT_READ: {
    de: 'In dieser Auflistung erhalten Sie weitergehende Informationen durch Klick auf das Pfeil-Symbol am Zeilenende. Bitte sehen Sie sich alle Listeneinträge an, damit dieser Abschnitt als bearbeitet gilt.'
  },
  LIST_MANDATORY_READ: {
    de: 'In dieser Auflistung erhalten Sie weitergehende Informationen durch Klick auf das Pfeil-Symbol am Zeilenende.'
  },
  LIST_NOT_MANDATORY: {
    de: 'In dieser Auflistung erhalten Sie weitergehende Informationen durch Klick auf das Pfeil-Symbol am Zeilenende. Diese Auflistung muss nicht bearbeitet werden, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  LIST_INFO: {
    de: 'In dieser Auflistung erhalten Sie weitergehende Informationen durch Klick auf das Pfeil-Symbol am Zeilenende.'
  },
  PDF_MANDATORY_NOT_READ: {
    de: 'Bitte öffnen Sie das PDF-Dokument, damit dieser Abschnitt als bearbeitet gilt.'
  },
  PDF_MANDATORY_READ: {
    de: 'Hier erhalten Sie Informationen zum Abspeichern und Ausdrucken im PDF-Format.'
  },
  PDF_NOT_MANDATORY: {
    de: 'Hier erhalten Sie Informationen zum Abspeichern und Ausdrucken im PDF-Format. Das Dokument muss nicht geöffnet werden, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  PDF_INFO: {
    de: 'Hier erhalten Sie Informationen zum Abspeichern und Ausdrucken im PDF-Format.'
  },
  QUIZ_MANDATORY_NOT_READ: {
    de: 'Bei diesem Symbol werden Sie auf die Probe gestellt: Testen Sie Ihr Wissen. Beantworten Sie bitte alle Fragen, damit dieser Abschnitt als bearbeitet gilt.'
  },
  QUIZ_MANDATORY_READ: {
    de: 'Bei diesem Symbol werden Sie auf die Probe gestellt: Testen Sie Ihr Wissen.'
  },
  QUIZ_NOT_MANDATORY: {
    de: 'Bei diesem Symbol werden Sie auf die Probe gestellt: Testen Sie Ihr Wissen. Der Abschnitt muss nicht bearbeitet werden, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  QUIZ_INFO: {
    de: 'Bei diesem Symbol werden Sie auf die Probe gestellt: Testen Sie Ihr Wissen.'
  },
  SWIPE_MANDATORY_NOT_READ: {
    de: 'Damit dieser Abschnitt als bearbeitet gilt, blättern Sie bitte per Klick auf die Pfeile durch alle Informationen.'
  },
  SWIPE_MANDATORY_READ: {
    de: 'Durch Klick auf die Pfeile erhalten Sie weitere Inhalte als Info-Galerie.'
  },
  SWIPE_NOT_MANDATORY: {
    de: 'Durch Klick auf die Pfeile erhalten Sie weitere Inhalte als Info-Galerie. Die Info-Galerie muss nicht bearbeitet werden, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  SWIPE_INFO: {
    de: 'Durch Klick auf die Pfeile erhalten Sie weitere Inhalte als Info-Galerie.'
  },
  URL_MANDATORY_NOT_READ: {
    de: 'Damit dieser Abschnitt als bearbeitet gilt, schauen Sie sich die angezeigte Internetseite an.'
  },
  URL_MANDATORY_READ: {
    de: 'Bei diesem Symbol erhalten Sie weitere Informationen über die angezeigte Internetseite.'
  },
  URL_NOT_MANDATORY: {
    de: 'Bei diesem Symbol erhalten Sie weitere Informationen per Internetseite. Die Seite muss nicht angeschaut werden, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  URL_INFO: {
    de: 'Bei diesem Symbol erhalten Sie weitere Informationen per Internetseite.'
  },
  VIDEO_MANDATORY_NOT_READ: {
    de: 'Damit dieser Abschnitt als bearbeitet gilt, sehen Sie sich bitte das Video an. Ein Anspielen ist ausreichend, aber für einen optimalen Lernerfolg sollten Sie sich den gesamten Inhalt aufmerksam ansehen.'
  },
  VIDEO_MANDATORY_READ: {
    de: 'Hier erwartet Sie ein Video.'
  },
  VIDEO_NOT_MANDATORY: {
    de: 'Hier erwartet Sie ein Video. Der Abschnitt muss nicht bearbeitet werden, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  VIDEO_INFO: {
    de: 'Hier erwartet Sie ein Video.'
  },
  VOTE_MANDATORY_NOT_READ: {
    de: 'Ihre Meinung ist gefragt. Damit dieser Abschnitt als bearbeitet gilt, stimmen Sie anonym ab und sehen Sie, welcher Meinung die anderen Teilnehmer waren.'
  },
  VOTE_MANDATORY_READ: {
    de: 'Ihre Meinung ist gefragt. Stimmen Sie anonym ab und sehen Sie, welcher Meinung die anderen Teilnehmer waren.'
  },
  VOTE_NOT_MANDATORY: {
    de: 'Ihre Meinung ist gefragt. Stimmen Sie anonym ab und sehen Sie, welcher Meinung die anderen Teilnehmer waren. Das Voting muss nicht bearbeitet werden, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  VOTE_INFO: {
    de: 'Ihre Meinung ist gefragt. Stimmen Sie anonym ab und sehen Sie, welcher Meinung die anderen Teilnehmer waren.'
  },
  RUBRIC_MANDATORY_NOT_READ: {
    de: 'Buchen Sie einen Termin in diesem Veranstaltungsthema, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  RUBRIC_MANDATORY_READ: {
    de: 'Bitte wählen Sie das Veranstaltungsthema aus, das Sie interessiert.'
  },
  RUBRIC_NOT_MANDATORY: {
    de: 'Bitte wählen Sie das Veranstaltungsthema aus, das Sie interessiert.'
  },
  RUBRIC_INFO: {
    de: 'Bitte wählen Sie das Veranstaltungsthema aus, das Sie interessiert.'
  },
  EVENTSERIES_MANDATORY_NOT_READ: {
    de: 'Buchen Sie einen Termin in dieser Veranstaltungsreihe, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  EVENTSERIES_MANDATORY_READ: {
    de: 'Bitte wählen Sie die Veranstaltungsreihe aus, an der Sie teilnehmen möchten.'
  },
  EVENTSERIES_NOT_MANDATORY: {
    de: 'Bitte wählen Sie die Veranstaltungsreihe aus, an der Sie teilnehmen möchten.'
  },
  EVENTSERIES_INFO: {
    de: 'Bitte wählen Sie die Veranstaltungsreihe aus, an der Sie teilnehmen möchten.'
  },
  FEEDBACK_MANDATORY_NOT_READ: {
    de: 'Geben Sie Feedback ab, um den Zustand "Bearbeitet" für die Lektion zu erreichen.'
  },
  FEEDBACK_MANDATORY_READ: {
    de: 'Hier können Sie Feedback abgeben.'
  },
  FEEDBACK_NOT_MANDATORY: {
    de: 'Hier können Sie Feedback abgeben.'
  },
  FEEDBACK_INFO: {
    de: 'Hier können Sie Feedback abgeben.'
  },
  LIKERT_MANDATORY_NOT_READ: {
    de: 'Bewerten Sie jede Aussage, um ein Gesamtergebnis zu sehen. Geben Sie zu jeder Aussage ihre Einschätzung, um den Zustand "Bearbeitet" für die Lektion zu erreichen'
  },
  LIKERT_MANDATORY_READ: {
    de: 'Bewerten Sie jede Aussage, um ein Gesamtergebnis zu sehen.'
  },
  LIKERT_NOT_MANDATORY: {
    de: 'Bewerten Sie jede Aussage, um ein Gesamtergebnis zu sehen.'
  },
  LIKERT_INFO: {
    de: 'Bewerten Sie jede Aussage, um ein Gesamtergebnis zu sehen.'
  },
  PODCAST_MANDATORY_NOT_READ: {
    de: 'Hören Sie sich den Podcast an. Hören Sie alle Folgen, um den Zustand "Bearbeitet" für die Lektion zu erreichen'
  },
  PODCAST_MANDATORY_READ: {
    de: 'Hören Sie sich den Podcast an.'
  },
  PODCAST_NOT_MANDATORY: {
    de: 'Hören Sie sich den Podcast an.'
  },
  PODCAST_INFO: {
    de: 'Hören Sie sich den Podcast an.'
  },
  MAZARS_CERT_CHECK_NAME_TEXT: {
    de: 'Ist der eingegebene Name $0 $1 richtig? Sie haben keine Möglichkeit, diesen im Nachhinein zu ändern!'
  },
  MAZARS_CERT_CHECK_NAME_TITLE: {
    de: 'Name korrekt?'
  },
  MAZARS_CERT_GET_CERT_ERROR: {
    de: 'Es gab einen Fehler beim Anfordern des Zertifikats: $0'
  },
  MAZARS_CERT_WARNING: {
    de: 'Bitte beachten Sie: Sie können den eingegebenen Namen nach dem Anfordern des Zertifikats nicht mehr ändern!'
  },
  MAZARS_CERT_CREATE_CERT: {
    de: 'Zertifikat anfordern'
  },
  MAZARS_CERT_GET_CERT: {
    de: 'Zertifikat herunterladen'
  },
  MAZARS_CERT_YOUR_CERT: {
    de: 'Ihr Zertifikat'
  },
  SEND_NOW: {
    de: 'ABSENDEN'
  },
  SEND_NOTES_TO_ME_NOW: {
    de: 'Notizen an meine E-Mail senden'
  },
  GIVE_USER_FEEDBACK: {
    de: 'Hier können Sie Ihr Feedback abgeben'
  },
  FEEDBACK_THANKS_TITLE: {
    de: 'Feedback versendet'
  },
  FEEDBACK_THANKS_TEXT: {
    de: 'Vielen Dank für Ihr Feedback'
  },
  REALLY_SEND_FEEDBACK_TITLE: {
    de: 'Feedback versenden?'
  },
  REALLY_SEND_FEEDBACK_TEXT: {
    de: "Diese Feedback-Email wird versendet an $0.<br/>Au\xDFerdem geht sie als Kopie an $1.<br/><br/>Soll diese Email versendet werden?"
  },
  FEEDBACK_ERROR_TEXT: {
    de: 'Es gab einen Fehler beim Versenden des Feedbacks: $0'
  },
  FEEDBACK_ERROR_TITLE: {
    de: 'Fehler beim Versenden'
  },
  FEEDBACK_ENTER_TITLE: {
    de: 'Keine Eingaben'
  },
  FEEDBACK_ENTER_TEXT: {
    de: 'Bitte geben Sie vor dem Versenden Feedback ab'
  },
  FEEDBACK_EMAIL_INFO: {
    de: 'Falls Sie damit einverstanden sind, dass wir Sie in Bezug auf Ihre Erfahrungen mit diesem Lernangebot kontaktieren, können Sie hier gerne Ihre eMail-Adresse hinterlassen. So helfen Sie uns, in Zukunft noch bessere Online-Trainings zu gestalten.'
  },
  FEEDBACK_SUBJECT_LABEL: {
    de: 'Betreff'
  },
  FEEDBACK_SUBJECT_PLACEHOLDER: {
    de: 'Geben Sie hier den Betreff ein'
  },
  FEEDBACK_TEXT_LABEL: {
    de: 'Bitte geben Sie hier Ihr Feedback ein'
  },
  FEEDBACK_TEXT_PLACEHOLDER: {
    de: 'Bitte geben Sie hier Ihr Feedback ein'
  },
  FEEDBACK_MAIL_SEND_SUCCESS_TITLE: {
    de: 'Feedback versendet'
  },
  FEEDBACK_MAIL_SEND_SUCCESS_TEXT: {
    de: 'Ihr Feedback wurde versendet. Herzlichen Dank für Ihr Interesse.'
  },
  FEEDBACK_MAIL_SEND_ERROR_TITLE: {
    de: 'Feedback nicht versendet'
  },
  FEEDBACK_MAIL_SEND_ERROR_TEXT: {
    de: 'Es gab einen Fehler beim Versenden des Feedbacks: $0'
  },
  NO_TO_MAILS_TEXT: {
    de: 'Es wurde kein Empfänger angegeben.'
  },
  NO_TO_MAILS_TITLE: {
    de: 'Mail konnte nicht versandt werden.'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;
var addStrings = _strings.addStrings;
exports.addStrings = addStrings;