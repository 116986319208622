"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.COLOR = exports.LightenDarkenColor = exports.isLightColorRGB = exports.isLightColor = void 0;

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/parse-int"));

var isLightColor = function isLightColor(hexString) {
  if (!hexString) {
    return false;
  }

  var colorString = hexString.charAt(0) === '#' ? hexString.substring(1) : hexString;
  var rgb = (0, _parseInt2["default"])(colorString, 16); // convert rrggbb to decimal

  var r = rgb >> 16 & 0xff; // extract red

  var g = rgb >> 8 & 0xff; // extract green

  var b = rgb >> 0 & 0xff; // extract bluconst

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma > 200;
};

exports.isLightColor = isLightColor;

var isLightColorRGB = function isLightColorRGB(rgbString) {
  if (!rgbString) {
    return false;
  }

  var colorString = rgbString.substring(4, rgbString.length - 1);
  var colorArray = colorString.split(',');
  var r = colorArray[0];
  var g = colorArray[1];
  var b = colorArray[2];
  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma > 200;
};

exports.isLightColorRGB = isLightColorRGB;

var LightenDarkenColor = function LightenDarkenColor(col, amt) {
  var usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  var num = (0, _parseInt2["default"])(col, 16);
  var r = (num >> 16) + amt;
  if (r > 255) r = 255;else if (r < 0) r = 0;
  var b = (num >> 8 & 0x00FF) + amt;
  if (b > 255) b = 255;else if (b < 0) b = 0;
  var g = (num & 0x0000FF) + amt;
  if (g > 255) g = 255;else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | b << 8 | r << 16).toString(16);
};

exports.LightenDarkenColor = LightenDarkenColor;
var COLOR = {
  BLACK: '#000000',
  WHITE: '#FFFFFF'
};
exports.COLOR = COLOR;