"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.shuffle = exports.waitForTurn = exports.deepEqual = exports.shallowEqual = exports.prefilledArray = exports.removeLastElement = exports.getSecondLastElement = exports.getLastElement = exports.getArrayWithoutLastElement = exports.removeElementAtPosition = exports.removeElementWithFindFunction = exports.removeElements = exports.removeElement = exports.removeElementsFromCopy = exports.removeElementFromCopy = exports.flattenArray = exports.IndexArray = void 0;

var _getIterator2 = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/get-iterator"));

var _iterator2 = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/symbol/iterator"));

var _symbol = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/symbol"));

var _from = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/array/from"));

var _promise = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/promise"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/object/keys"));

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/json/stringify"));

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/array/is-array"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/toConsumableArray"));

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof _symbol["default"] === "undefined" || o[_iterator2["default"]] == null) { if ((0, _isArray["default"])(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = (0, _getIterator2["default"])(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return (0, _from["default"])(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/**
 *  IndexArray
 *
 *  This is syntactic sugar only. You can create an array by giving a count and use this array for convenient
 *  ES6 array operation which you can't by simply calling `new Array(n)`
 *
 *  Ex:
 *      const result = IndexArray(3).map(i => `value ${i}`))
 *
 *      => result = ["value 0", "value 1", "value 2"]
 */
var IndexArray = function IndexArray(n) {
  return (0, _toConsumableArray2["default"])(new Array(n)).map(function (_, index) {
    return index;
  });
};

exports.IndexArray = IndexArray;

var flattenArray = function flattenArray(list) {
  return list.reduce(function (a, b) {
    return a.concat((0, _isArray["default"])(b) ? flattenArray(b) : b);
  }, []);
};

exports.flattenArray = flattenArray;

var removeElementFromCopy = function removeElementFromCopy(array, element) {
  return array.filter(function (elem) {
    return elem !== element;
  });
};

exports.removeElementFromCopy = removeElementFromCopy;

var removeElementsFromCopy = function removeElementsFromCopy(array, elements) {
  return array.filter(function (elem) {
    return elements.indexOf(elem) < 0;
  });
};

exports.removeElementsFromCopy = removeElementsFromCopy;

var removeElement = function removeElement(array, element) {
  var index = array.indexOf(element);

  if (index >= 0) {
    array.splice(index, 1);
  }
};

exports.removeElement = removeElement;

var removeElements = function removeElements(array, elements) {
  elements.forEach(function (element) {
    removeElement(array, element);
  });
};

exports.removeElements = removeElements;

var removeElementWithFindFunction = function removeElementWithFindFunction(array, findMethod) {
  var l = array.length;
  var index = -1;

  for (var i = 0; i < l; i++) {
    if (findMethod(array[i], i, array)) {
      index = i;
      break;
    }
  }

  if (index !== -1) {
    array.splice(index, 1);
  }
};

exports.removeElementWithFindFunction = removeElementWithFindFunction;

var removeElementAtPosition = function removeElementAtPosition(array, index) {
  if (index >= 0) {
    array.splice(index, 1);
  }
};

exports.removeElementAtPosition = removeElementAtPosition;

var getArrayWithoutLastElement = function getArrayWithoutLastElement(array) {
  return array.slice(0, array.length - 1);
};

exports.getArrayWithoutLastElement = getArrayWithoutLastElement;

var getLastElement = function getLastElement(array) {
  if (array && array.length > 0) {
    return array[array.length - 1];
  }

  return null;
};

exports.getLastElement = getLastElement;

var getSecondLastElement = function getSecondLastElement(array) {
  if (array && array.length > 1) {
    return array[array.length - 2];
  }

  return null;
};

exports.getSecondLastElement = getSecondLastElement;

var removeLastElement = function removeLastElement(array) {
  if (array && array.length > 0) {
    removeElementAtPosition(array, array.length - 1);
  }
};

exports.removeLastElement = removeLastElement;

var prefilledArray = function prefilledArray(length, value) {
  return Array.apply(null, Array(length)).map(function () {
    return value;
  });
};
/**
 *
 * @param arr1 {Array}  The first array
 * @param arr2 {Array}  The second array
 * @param numberOfElementsToCheck {int}   The first n elements will be compared, all others will be ignored
 * @return {boolean}
 */


exports.prefilledArray = prefilledArray;

var shallowEqual = function shallowEqual(arr1, arr2) {
  var numberOfElementsToCheck = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var arr1ToCheck = numberOfElementsToCheck ? arr1.slice(0, numberOfElementsToCheck) : arr1;
  var arr2ToCheck = numberOfElementsToCheck ? arr2.slice(0, numberOfElementsToCheck) : arr2;
  return (0, _stringify["default"])(arr1ToCheck) === (0, _stringify["default"])(arr2ToCheck);
}; // also works for objects


exports.shallowEqual = shallowEqual;

var deepEqual = function deepEqual(val1, val2) {
  var numberOfElementsToCheck = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var arrayType = '[object Array]';
  var objectType = '[object Object]';
  var type1 = Object.prototype.toString.call(val1);
  var type2 = Object.prototype.toString.call(val2);

  if (type1 !== type2) {
    return false;
  }

  if ([arrayType, objectType].indexOf(type1) < 0) {
    return false;
  }

  var keyLen1 = type1 === arrayType ? val1.length : (0, _keys["default"])(val1).length;
  var keyLen2 = type2 === arrayType ? val2.length : (0, _keys["default"])(val2).length;

  if (keyLen1 !== keyLen2) {
    return false;
  }

  var toCheck = numberOfElementsToCheck || keyLen1;

  if (type1 === arrayType) {
    for (var i = 0; i < toCheck; i++) {
      if (!compare(val1[i], val2[i])) {
        return false;
      }
    }
  } else {
    var keys = (0, _keys["default"])(val1).slice(0, toCheck);

    var _iterator = _createForOfIteratorHelper(keys),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var key = _step.value;

        if (!val2.hasOwnProperty(key) || !compare(val1[key], val2[key])) {
          return false;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }

  return true;
};

exports.deepEqual = deepEqual;

function compare(val1, val2) {
  var type1 = Object.prototype.toString.call(val1);
  var type2 = Object.prototype.toString.call(val2);
  var arrayType = '[object Array]';
  var objectType = '[object Object]';
  var functionType = '[object Function]';

  if ([arrayType, objectType].indexOf(type1) >= 0) {
    return deepEqual(val1, val2);
  } else {
    if (type1 !== type2) {
      return false;
    }

    if (type1 === functionType) {
      return val1.toString() === val2.toString();
    } else {
      return val1 === val2;
    }
  }
}
/*
export const deepEqual = (arr1, arr2, numberOfElementsToCheck = null) => {
  throw new Error('Deep equal not yet implemented, use shallowEqual as fallback or implement')
}
*/


var waitForTurn = function waitForTurn(orderArray) {
  return new _promise["default"](function (resolve) {
    var onReady = function onReady() {
      resolve(function () {
        // this is the release callback function
        removeElementAtPosition(orderArray, 0);

        if (orderArray.length > 0) {
          // trigger next waiting thread in line
          orderArray[0]();
        }
      });
    };

    orderArray.push(function () {
      onReady();
    });

    if (orderArray.length === 1) {
      // then it's me
      onReady();
    }
  });
};

exports.waitForTurn = waitForTurn;

var shuffle = function shuffle(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var _ref = [array[j], array[i]];
    array[i] = _ref[0];
    array[j] = _ref[1];
  }

  return array;
};

exports.shuffle = shuffle;