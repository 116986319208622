"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  DEFAULT_USER_FEEDBACK_SUBJECT: {
    de: 'Feedback zu einer Frage'
  },
  QUESTION: {
    de: 'Frage'
  },
  SEND_FEEDBACK: {
    de: 'Feedback abschicken'
  },
  FEEDBACK_EXPLANATION_MESSAGE: {
    de: 'An dieser Frage ist dir etwas aufgefallen? Hier kannst du es uns mitteilen:'
  },
  FEEDBACK_EXPLANATION_TITLE: {
    de: 'Feedback zur Frage'
  },
  MESSAGE: {
    de: 'Nachricht'
  },
  QD_TOURNAMENT_CANT_START_NEW_GAMES: {
    de: '<p style="color: red;">Es können keine neuen Spiele mehr gestartet werden.</p>'
  },
  CATEGORIES: {
    de: 'Kategorien'
  },
  TOTAL: {
    de: 'Gesamt'
  },
  MONTHLY: {
    de: 'Monatlich'
  },
  DAILY: {
    de: 'Täglich'
  },
  LETS_GO: {
    de: 'Los geht\'s!'
  },
  NOT_NOW: {
    de: 'Später'
  },
  START_NEW_GAME: {
    de: 'Möchtest du $0 herausfordern?'
  },
  NEW_GAME: {
    de: 'Neues Spiel'
  },
  RUNNING_GAMES: {
    de: 'Übersicht Spiele'
  },
  QUIZ_EMAIL: {
    de: 'Deine E-Mail'
  },
  ENTER_MESSAGE: {
    de: 'Bitte Nachricht eingeben'
  },
  PUSH_REMINDER_EXPLANATION: {
    de: 'Sende $0 eine Nachricht!'
  },
  PUSH_REMINDER: {
    de: 'Nachricht senden'
  },
  PUSH_RANDOM: {
    de: 'Vorschlag'
  },
  PUSH_TOO_OFTEN: {
    de: 'Du musst einen Moment warten, bevor du die nächste Nachricht senden kannst.'
  },
  PUSH_TOO_OFTEN_TITLE: {
    de: 'Zu schnell!'
  },
  RANDOM_PUSH_1: {
    de: 'Ich warte auf Dich. Jetzt spielen!'
  },
  RANDOM_PUSH_2: {
    de: 'Was ist los? Tritt gegen mich an!'
  },
  RANDOM_PUSH_3: {
    de: 'Hallo? Ich warte auf Dein Spiel.'
  },
  RANDOM_PUSH_4: {
    de: 'Bitte, bitte! Jetzt spielen.'
  },
  RANDOM_PUSH_5: {
    de: 'Wann spielst Du wieder?'
  },
  RANDOM_PUSH_6: {
    de: 'Wann geht es weiter?'
  },
  RANDOM_PUSH_7: {
    de: 'Geht es bald weiter?'
  },
  RANDOM_PUSH_8: {
    de: 'Seufz... Wann spielst Du wieder?'
  },
  MY_GAMES: {
    de: 'Meine Spiele'
  },
  MY_GAMES_LEAGUE: {
    de: 'Meine Partien'
  },
  LEAGUE: {
    de: 'Liga'
  },
  ALL_PLAYERS: {
    de: 'Alle Spieler'
  },
  ALL_PLAYERS_LEAGUE: {
    de: 'Mein Team'
  },
  HIGHSCORES: {
    de: 'Rangliste'
  },
  HIGHSCORES_LEAGUE: {
    de: 'Punkte'
  },
  QUIZ_PROGRESSION: {
    de: 'Lernfortschritt'
  },
  OVERVIEW: {
    de: 'Ergebnis'
  },
  JUMP_TO_THE_GAME: {
    de: 'Spielen'
  },
  TO_QUIZ_HOME: {
    de: 'Zurück zur Spielübersicht'
  },
  NEXT: {
    de: 'Weiter'
  },
  REVANCHE: {
    de: 'Neues Spiel!'
  },
  NEXT_ROUND: {
    de: 'Nächste Runde'
  },
  OVERVIEW_CHOOSE_CATEGORY: {
    de: 'Nächste Runde'
  },
  OVERVIEW_PLAY_ROUND: {
    de: 'Nächste Runde'
  },
  TIE: {
    de: 'Unent-schieden!'
  },
  TIE_LEAGUE: {
    de: 'Jeder Spieler bekommt einen Punkt'
  },
  WON: {
    de: 'Gewonnen!'
  },
  WON_LEAGUE: {
    de: 'Du hast damit 3 Punkte verdient!'
  },
  LOST: {
    de: 'Verloren!'
  },
  LOST_LEAGUE: {
    de: ''
  },
  CHOOSE_OPPONENT: {
    de: 'Auswahl'
  },
  RANDOM_OPPONENT: {
    de: 'Zufallsgegner'
  },
  FROM_GALLERY: {
    de: 'Aus Galerie wählen'
  },
  FROM_CAMERA: {
    de: 'Mit Kamera aufnehmen'
  },
  CHOOSE_IMAGE_SOURCE_TITLE: {
    de: 'Neues Profilbild'
  },
  CHOOSE_IMAGE_SOURCE_TEXT: {
    de: 'Wähle die Quelle für dein neues Profilbild:'
  },
  CHOOSE_CATEGORY: {
    de: 'Rubrik wählen'
  },
  NO_QUESTIONS_FROM_SERVER: {
    de: 'Keine Fragen vom Server erhalten'
  },
  START: {
    de: 'Start'
  },
  BACK_TO_CATEGORY_SELECTION: {
    de: 'Weiter lernen'
  },
  BACK_TO_STATISTIC: {
    de: 'Zurück zur Statistik'
  },
  SEND_SELF_EVALUATION: {
    de: 'Bewertung abschicken'
  },
  RATE_YOUR_ANSWERS: {
    de: 'Bewerten Sie Ihre Antworten'
  },
  MODE_CHOOSER: {
    de: 'Spielmodus wählen'
  },
  PLAY: {
    de: 'Spielen'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;