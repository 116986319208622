"use strict";

var _interopRequireWildcard = require("@babel/runtime-corejs2/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/classCallCheck"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/defineProperty"));

var _DataStore = _interopRequireWildcard(require("../KESI2/Stores/DataStore2"));

var _MuiAlertHelper = require("./MuiAlertHelper");

var _TokenHelperStrings = require("./TokenHelperStrings");

var _config = _interopRequireDefault(require("../../targets/cord_eurofh_teams/config.js"));

var _DeviceInfoHelper = _interopRequireDefault(require("./DeviceInfoHelper"));

var CourseHelper = function CourseHelper(props) {
  (0, _classCallCheck2["default"])(this, CourseHelper);
  this.token = '';
};

exports["default"] = CourseHelper;
(0, _defineProperty2["default"])(CourseHelper, "setToken", function (event) {
  if (event && event.target) {
    CourseHelper.token = event.target.value;
  }
});
(0, _defineProperty2["default"])(CourseHelper, "checkToken", function (courseName) {
  var error = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  var features = _DataStore["default"].getElementByPathImmediately(_DataStore.DATA_STORE_PATHS.FEATURES);

  var targetFeature = features[_config["default"].requiredFeature];

  if (targetFeature && targetFeature.additionalData && targetFeature.additionalData.courses) {
    var courseBooking = targetFeature.additionalData.courses[courseName];

    if (courseBooking && courseBooking.booked === true && courseBooking.active !== true) {
      var buttons = [{
        label: (0, _TokenHelperStrings.getString)('OK'),
        callback: function callback() {
          console.log('OK Button', courseBooking, CourseHelper.token, courseBooking.token, CourseHelper.token === courseBooking.token);

          if (!CourseHelper.token || CourseHelper.token === '') {
            setTimeout(function () {
              CourseHelper.checkToken(courseName, (0, _TokenHelperStrings.getString)('ENTER_TOKEN_ERROR'));
            });
          } else if (CourseHelper.token !== courseBooking.token.toString()) {
            setTimeout(function () {
              CourseHelper.checkToken(courseName, (0, _TokenHelperStrings.getString)('ENTER_TOKEN_ERROR_NO_MATCH'));
            });
          } else {
            CourseHelper.saveToken(courseName);
          }
        }
      }, {
        label: (0, _TokenHelperStrings.getString)('CANCEL'),
        callback: function callback() {}
      }];
      (0, _MuiAlertHelper.showWideAlertHtmlWithTextfields)(_DeviceInfoHelper["default"].isBrowser() ? (0, _TokenHelperStrings.getString)('ENTER_TOKEN') : (0, _TokenHelperStrings.getString)('ENTER_TOKEN_MOBILE'), (0, _TokenHelperStrings.getString)('ENTER_TOKEN_TITLE'), error, [{
        label: (0, _TokenHelperStrings.getString)('TOKEN'),
        onChange: CourseHelper.setToken,
        value: CourseHelper.token,
        style: {
          width: '51%'
        }
      }], true, null, buttons);
    }
  }
});
(0, _defineProperty2["default"])(CourseHelper, "saveToken", function (courseName) {
  var features = _DataStore["default"].getElementByPathImmediately(_DataStore.DATA_STORE_PATHS.FEATURES);

  var targetFeature = features[_config["default"].requiredFeature];

  if (targetFeature && targetFeature.additionalData && targetFeature.additionalData.courses) {
    var bookings = targetFeature.additionalData.courses;
    var courseBooking = bookings[courseName];
    courseBooking.active = true;
    console.log('saveToken', courseBooking);

    _DataStore["default"].setSingleData("".concat(_DataStore.DATA_STORE_PATHS.FEATURES, ".").concat(_config["default"].requiredFeature, ".additionalData.courses.").concat(courseName), courseBooking);

    (0, _MuiAlertHelper.showWideAlertHtml)((0, _TokenHelperStrings.getString)('ENTER_TOKEN_SUCCES'), (0, _TokenHelperStrings.getString)('ENTER_TOKEN_SUCCESS_TITLE'));
  }
});
(0, _defineProperty2["default"])(CourseHelper, "activateCourseWithToken", function (params) {
  var courseName = params.courseName;
  var token = params.token;
  var email = params.email;

  var userData = _DataStore["default"].getElementByPathImmediately(_DataStore.DATA_STORE_PATHS.USER_PROFILE);

  var loginEmail = userData.email;

  var features = _DataStore["default"].getElementByPathImmediately(_DataStore.DATA_STORE_PATHS.FEATURES);

  var targetFeature = features[_config["default"].requiredFeature];

  if (targetFeature && targetFeature.additionalData && targetFeature.additionalData.courses) {
    var courseData = targetFeature.additionalData.courses[courseName];

    if (loginEmail !== email || courseData && token !== courseData.token) {
      return (0, _MuiAlertHelper.showWideAlertHtml)((0, _TokenHelperStrings.getString)('WRONG_USER', 'WRONG_EMAIL_TITLE'));
    } else {
      CourseHelper.saveToken(courseName);
    }
  }
});