"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _parseFloat2 = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/parse-float"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/slicedToArray"));

var _LogService = require("../../../../KESI2/Services/LogService");

var _ResumeSuspendListeners = require("../../../../helpers/ResumeSuspendListeners");

var _react = require("react");

var _AudioPlayer = require("../../AudioPlayer/AudioPlayer2");

function ListenedSequences(props) {
  var currentTrack = props.currentTrack,
      podcastNugget = props.podcastNugget,
      playerState = props.playerState,
      currentTime = props.currentTime,
      prevTime = props.prevTime;

  var _useState = (0, _react.useState)([]),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      sequences = _useState2[0],
      setSequences = _useState2[1];

  var _useState3 = (0, _react.useState)(0),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      latestStart = _useState4[0],
      setLatestStart = _useState4[1];

  (0, _react.useEffect)(function () {
    (0, _ResumeSuspendListeners.addOnSuspendListener)(postLogMessage);
    return function () {
      postAndUnload();
    };
  }, []);
  (0, _react.useEffect)(function () {
    if (playerState === _AudioPlayer.MEDIA_STATUS.RUNNING) {
      setLatestStart(currentTime);
    } else if (playerState === _AudioPlayer.MEDIA_STATUS.PAUSED) {
      _addCurrentSequence();
    } else if (playerState === _AudioPlayer.MEDIA_STATUS.STOPPED) {
      _addCurrentSequence();
    }
  }, [playerState]);
  (0, _react.useEffect)(function () {
    if (currentTime - prevTime > 0.5 && prevTime > 0) {
      _addCurrentSequence(latestStart, prevTime);

      setLatestStart(currentTime);
    }
  }, [prevTime]);
  (0, _react.useEffect)(function () {
    postLogMessage();

    _reset();
  }, [currentTrack]);

  var postAndUnload = function postAndUnload() {
    postLogMessage();
    (0, _ResumeSuspendListeners.removeOnSuspendListener)(postLogMessage);
  };

  var _addCurrentSequence = function _addCurrentSequence() {
    var startTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var endTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var drupalEl = podcastNugget.audios.find(function (audio) {
      return audio.audioFile === currentTrack;
    });

    if (drupalEl && currentTrack) {
      var drupalId = drupalEl.id;

      if (currentTime > latestStart + 1) {
        var updatedSequences = sequences;
        updatedSequences.push({
          start: (0, _parseFloat2["default"])(startTime || latestStart),
          end: (0, _parseFloat2["default"])(endTime || currentTime),
          trackId: (0, _parseFloat2["default"])(drupalId)
        });
        console.log('_addCurrentSequence updated sequences', sequences);
        setSequences(updatedSequences);
      }
    }

    setLatestStart(0);
  };

  var _reset = function _reset() {
    setLatestStart(0);
    setSequences([]);
  };

  var postLogMessage = function postLogMessage() {
    _addCurrentSequence();

    if (sequences.length > 0) {
      (0, _LogService.pushAudioUsageLogMessage)(podcastNugget.id, null, sequences);
    }

    return _reset();
  };

  return null;
}

var _default = ListenedSequences;
exports["default"] = _default;