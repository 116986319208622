"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.translateOnlineSettings = void 0;

var _config = _interopRequireDefault(require("../../targets/cord_eurofh_teams/config.js"));

var _PreconditionChecker = require("../utils/PreconditionChecker");

function isNumeric(value) {
  return /^-{0,1}\d+$/.test(value);
}

var translateOnlineSettings = function translateOnlineSettings(settings) {
  var translatedSettings = [];

  if (settings.length > 0) {
    settings.forEach(function (singleSetting) {
      var translatedSingleSetting = {};

      if (singleSetting.label) {
        translatedSingleSetting.label = singleSetting.label;
      }

      if (singleSetting.callback) {
        if (_config["default"].settingsFnMap && _config["default"].settingsFnMap.callbacks && _config["default"].settingsFnMap.callbacks[singleSetting.callback]) {
          translatedSingleSetting.callback = _config["default"].settingsFnMap.callbacks[singleSetting.callback];
        } else {
          console.log('settingsHelper -> cant translate callback ', singleSetting.callback);
        }
      }

      if (singleSetting.condition) {
        if (_config["default"].settingsFnMap && _config["default"].settingsFnMap.conditions && _config["default"].settingsFnMap.conditions[singleSetting.condition]) {
          translatedSingleSetting.condition = _config["default"].settingsFnMap.conditions[singleSetting.condition];
        } else {
          console.log('settingsHelper -> cant translate condition ', singleSetting.condition);
        }
      }

      if (singleSetting.precondition) {
        console.log('settingsHelper -> precondition ', singleSetting.precondition);
        var failingPrecondition = (0, _PreconditionChecker.getFailingPrecondition)(singleSetting.precondition);

        if (!failingPrecondition) {
          translatedSettings.push(translatedSingleSetting);
        }
      }
    });
  }

  return translatedSettings;
};

exports.translateOnlineSettings = translateOnlineSettings;