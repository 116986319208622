"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../../config/strings");

var STRINGS = {
  DOWNLOAD_VIDEO: {
    de: 'Herunterladen'
  },
  DOWNLOAD_VIDEO_SHORT: {
    de: 'Save'
  },
  SHARE_VIDEO: {
    de: 'Teilen'
  },
  SHARE_VIDEO_SHORT: {
    de: 'Share'
  },
  DELETE_VIDEO: {
    de: 'Video löschen',
    en: 'Delete from device'
  },
  DELETE_VIDEO_SHORT: {
    de: 'Löschen',
    en: 'Delete'
  },
  YOU_ARE_OFFLINE: {
    de: 'Sie sind offline.',
    en: 'You are offline.'
  },
  YOU_ARE_OFFLINE_SHORT: {
    de: 'Offline',
    en: 'Offline'
  },
  CHOOSE_CHAPTER: {
    de: 'Kapitel auswählen',
    en: 'Choose a chapter'
  },
  DOWNLOADING: {
    de: 'Herunterladen...',
    en: 'Downloading...'
  },
  SAVING: {
    de: 'Auf Gerät speichern...',
    en: 'Saving on device...'
  },
  SAVING_SHORT: {
    de: 'Speichern...',
    en: 'Saving...'
  },
  POWER_MODE_ENABLED: {
    de: 'Sie haben den Low Power Mode aktiviert. Dies kann dazu führen, dass Videos nicht abspielbar sind '
  },
  WATCH_OUT: {
    de: 'Achtung'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;