"use strict";

var _interopRequireWildcard = require("@babel/runtime-corejs2/helpers/interopRequireWildcard");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.mergeJsonObjects = exports.addJsonObjects = exports.makeDeepCopy = void 0;

var JSONPatch = _interopRequireWildcard(require("fast-json-patch"));

var makeDeepCopy = function makeDeepCopy(obj1) {
  var clone = JSONPatch.deepClone(obj1);
  return clone;
};

exports.makeDeepCopy = makeDeepCopy;

var addJsonObjects = function addJsonObjects(obj1, obj2) {
  var diff = JSONPatch.compare(obj1, obj2);
  var addDiff = diff.filter(function (op) {
    return op.op !== 'remove';
  });
  var newObj = JSONPatch.applyPatch(obj1, addDiff).newDocument;
  return newObj;
};

exports.addJsonObjects = addJsonObjects;

var mergeJsonObjects = function mergeJsonObjects(obj1, obj2) {
  var diff = JSONPatch.compare(obj1, obj2);
  var newObj = JSONPatch.applyPatch(obj1, diff).newDocument;
  return newObj;
};

exports.mergeJsonObjects = mergeJsonObjects;