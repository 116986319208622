"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var css = {
  'colors': {
    'titleBackgroundOverlay': 'rgba(255, 255, 255, 0.0)',
    'tileFontColor': '#ffffff',
    'tileFontWeight': '300',
    'tileBackgroundColor': '#FFFFFF',
    'navbarBackground': '#FFFFFF',
    'navbarBorderBottom': '1px solid #9b9b9b',
    'navbarText': '#00518e',
    'menuBackground': '#FFFFFF',
    'buttonBorder': '#f0af00',
    'buttonBorderSecondary': '#f0af00',
    'buttonCaption': '#303d4f',
    'buttonCaptionSecondary': '#FFFFFF',
    'buttonBackground': '#f0af00',
    'buttonBackgroundLight': '#c6defa',
    'buttonBorderLight': '#8faed6',
    'buttonCaptionLight': '#8faed6',
    'buttonBackgroundDark': '#f4f4f4',
    'buttonBorderDark': '#f4f4f4',
    'buttonCaptionDark': '#00518e',
    'lightText': '#00518e',
    'textfieldCaption': '#00518e',
    'tab': '#39597B',
    'tabBackground': '#FFFFFF',
    'tableDivider': '#D8D8D8',
    'dialogText': '#6f6760',
    'dialogDivider': '#a69b8d',
    'dialogBackground': '#fbf9f7',
    'primaryColor1': '#ff2337',
    'primaryColor2': '#e32233',
    'navbarItemColor': '#303d4f',
    'lectureBackButton': 'green',
    'lectureHeaderText': '#ffffff',
    'pieChartColor': '#f0af00',
    'donutChartColors': ['#f0af00', '#00518e', '#303d4f', '#8faed6']
  },
  'fontFamily': 'Oxygen',
  'navBarMaxWidth': '45%',
  'navBarContainerLeftTop': '20px',
  'navBarHeight': 64,
  'navBarHeightPx': '64px',
  'navBarLogoMarginTop': 10,
  'navBarRightSideButtonsTop': '24px',
  'navBarLogoLeft': '10%',
  'navBarLogoHeight': '35px',
  'navBarLogoTop': '20px',
  'thresholdScreenWidth': '800px',
  'mainMenuWidth': '300px',
  'navBarMargin': '0 40px',
  'navBarMaxHeight': '60px',
  'navBarTextAlign': 'center',
  'navBarVerticalAlign': 'middle',
  'navBarFontSize': '16px',
  'navBarFontWeight': 'bold',
  'navbarTitleColor': '#014170',
  'navBarPaddingTop': '14px',
  'navBarLineHeight': '19px',
  'burgerIconLeft': '10px',
  'navBarOverflow': 'hidden',
  'lectureBackButton': 'green',
  'headerMinHeight': '160px',
  'appMaxWidth': 1024,
  'tiles': {
    'backgroundHeight': 99,
    'minTitleHeight': 97,
    'subtitleHeight': 72,
    'titleSize': 12,
    'iconSize': 80,
    'iconRight': '50%',
    'iconTransform': 'translateX(50%)',
    'backgroundColor': 'rgba(255,255,255,0.0)',
    'blur': 'blur(0)'
  }
};
var _default = css;
exports["default"] = _default;