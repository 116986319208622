"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getValuesFromObject = getValuesFromObject;
exports.getValuesForPrecondition = getValuesForPrecondition;

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/array/is-array"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/toConsumableArray"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/object/keys"));

function isObject(variable) {
  return Object.prototype.toString.call(variable) === '[object Object]';
}

var getIsAdd = function getIsAdd(obj) {
  if (obj === null || obj === void 0 ? void 0 : obj.isPreconditionData) return [obj];else return (0, _keys["default"])(obj).reduce(function (prev, key) {
    if (isObject(obj[key])) {
      var _obj$key;

      if ((_obj$key = obj[key]) === null || _obj$key === void 0 ? void 0 : _obj$key.isPreconditionData) return [].concat((0, _toConsumableArray2["default"])(prev), [obj[key]]);else return [].concat((0, _toConsumableArray2["default"])(prev), (0, _toConsumableArray2["default"])(getIsAdd(obj[key])));
    } else return prev;
  }, []);
};

function getValuesFromObject(obj) {
  return (0, _keys["default"])(obj).reduce(function (prev, key) {
    if (key === 'value') {
      if ((0, _isArray["default"])(obj.value)) return [].concat((0, _toConsumableArray2["default"])(prev), (0, _toConsumableArray2["default"])(obj.value));else return [].concat((0, _toConsumableArray2["default"])(prev), [obj.value]);
    } else if (isObject(obj[key])) return [].concat((0, _toConsumableArray2["default"])(prev), (0, _toConsumableArray2["default"])(getValuesFromObject(obj[key])));else return prev;
  }, []);
}

function getValuesForPrecondition(obj) {
  if (!obj || !isObject(obj)) return [];
  var objToAddFromArr = getIsAdd(obj);
  return objToAddFromArr.reduce(function (prev, obj) {
    return prev.concat(getValuesFromObject(obj));
  }, []);
}