"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/typeof"));

var _isArray = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/array/is-array"));

var _isInteger = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/number/is-integer"));

var ooPointer = require('json8-pointer');

var decode = ooPointer.decode;
var validArrayToken = ooPointer.validArrayToken;
var OBJECT = 'object';

function isArrayIndex(token) {
  return token === '-' || (0, _isInteger["default"])(+token) && Math.abs(+token) === +token;
}

function walkWithCreate(doc, tokens) {
  var length = tokens.length;
  var i = 0;
  var lastTarget = null;
  var lastToken = null;
  var target = doc;
  var token;

  while (i < length - 1) {
    token = tokens[i++].toString();

    if ((0, _isArray["default"])(target)) {
      validArrayToken(token, target.length);
    } else if ((0, _typeof2["default"])(target) !== OBJECT || target === null) {
      if (lastTarget !== null) {
        if (isArrayIndex(token) && false) {
          lastTarget[lastToken] = [];
          if (token === '-') token = '0';
        } else {
          lastTarget[lastToken] = {};
        }

        target = lastTarget[lastToken];
      } else {
        throw new Error('Cannot be walked');
      }
    }

    lastTarget = target;
    lastToken = token;
    target = target[token];
  }

  token = tokens[i];

  if ((0, _isArray["default"])(target)) {
    validArrayToken(token, target.length);
  } else if ((0, _typeof2["default"])(target) !== OBJECT || target === null) {
    if (lastTarget !== null) {
      if (isArrayIndex(token)) {
        lastTarget[lastToken] = [];
        if (token === '-') token = '0';
      } else {
        lastTarget[lastToken] = {};
      }

      target = lastTarget[lastToken];
    } else {
      throw new Error('Cannot be walked');
    }
  }

  return [token, target];
}

function add(doc, path, value) {
  var tokensToConvert = decode(path);
  var tokens = tokensToConvert.map(function (token) {
    return token.toString();
  }); // replaces the document

  if (tokens.length === 0) return {
    doc: value,
    previous: doc
  };
  var r = walkWithCreate(doc, tokens);
  var token = r[0];
  var parent = r[1];
  var previous;
  var idx;

  if ((0, _isArray["default"])(parent)) {
    if (token === '-') {
      parent.push(value);
      idx = parent.length - 1;
    } else {
      parent.splice(token, 0, value);
    }
  } else {
    previous = parent[token];
    parent[token] = value;
  }

  return {
    doc: doc,
    previous: previous,
    idx: idx
  };
}

var _default = add;
exports["default"] = _default;