"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  QR_SCANNER_HEADLINE: {
    de: 'QR-Code scannen',
    en: 'Scan QR Code'
  },
  QR_SCANNER_INFOTEXT: {
    de: 'Halten Sie die Kamera so, dass der QR-Code im Kästchen positioniert ist.',
    en: 'Scan the QR code by centering it in the box.'
  },
  QR_UNKNOWN_CONTENT: {
    de: 'Der QR-Code ist für diese App nicht geeignet.',
    en: 'Sorry, this is not a code for this App.'
  },
  QR_PERMISSION_DENIED_OPEN_SETTINGS: {
    de: 'Die App hat gerade keinen Zugriff auf die Kamera. Du kannst das in den Einstellungen ändern.',
    en: 'The App has no access to your camera. Please give it access in your devices settings.'
  },
  OPEN_CAMERA_SETTINGS: {
    de: 'Einstellungen öffnen',
    en: 'Open settings'
  },
  QR_INVALID: {
    de: 'Oops - die App konnte für diesen Code keinen Inhalt finden.',
    en: 'Oops - No content for this QR Code...'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;