"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.deepMerge = exports.getObjectValueWithFallbacks = void 0;

var getObjectValueWithFallbacks = function getObjectValueWithFallbacks(object, value, fallbackObject, fallbackValue, secondFallbackString) {
  if (object && object[value]) {
    return object[value];
  }

  return fallbackObject && fallbackObject[fallbackValue] ? fallbackObject[fallbackValue] : secondFallbackString;
};

exports.getObjectValueWithFallbacks = getObjectValueWithFallbacks;

var deepMerge = function deepMerge(target) {
  for (var _len = arguments.length, sources = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    sources[_key - 1] = arguments[_key];
  }

  for (var _i = 0, _sources = sources; _i < _sources.length; _i++) {
    var source = _sources[_i];

    for (var k in source) {
      var vs = source[k],
          vt = target[k];

      if (Object(vs) === vs && Object(vt) === vt) {
        target[k] = deepMerge(vt, vs);
        continue;
      }

      target[k] = source[k];
    }
  }

  return target;
};

exports.deepMerge = deepMerge;