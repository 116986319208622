"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.addStrings = exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  EVENT_TITLE: {
    de: 'Titel',
    en: 'Title'
  },
  EVENT_PERIOD: {
    de: 'Zeitraum',
    en: 'Period'
  },
  START_DATE: {
    de: 'Beginn',
    en: 'Start'
  },
  END_DATE: {
    de: 'Ende',
    en: 'End'
  },
  OUT_OF: {
    de: 'von',
    en: 'of'
  },
  DOCENT: {
    de: 'Dozent',
    en: 'Docent'
  },
  DOCENTS: {
    de: 'Dozenten',
    en: 'Docents'
  },
  VENUE: {
    de: 'Veranstaltungsort',
    en: 'Venue'
  },
  VENUES: {
    de: 'Veranstaltungsorte',
    en: 'Venues'
  },
  FREE_SEATS: {
    de: 'Freie Plätze',
    en: 'Available seats'
  },
  CONFIRM_BOOKING: {
    de: 'Buchung bestätigen',
    en: 'Confirm booking'
  },
  CONFIRM_BOOKING_DETAIL: {
    de: 'Möchten Sie diesen Termin jetzt buchen?',
    en: 'Would you like to book this event now?'
  },
  CONFIRM_CANCELING: {
    de: 'Stornierung bestätigen',
    en: 'Confirm canceling'
  },
  CONFIRM_CANCELING_DETAIL: {
    de: 'Möchten Sie diese Buchung jetzt stornieren?',
    en: 'Would you like to cancel this event booking now?'
  },
  BOOK: {
    de: 'Buchen',
    en: 'Book'
  },
  BOOK_NOW: {
    de: 'Jetzt buchen',
    en: 'Book now'
  },
  CANCEL_NOW: {
    de: 'Buchung jetzt stornieren',
    en: 'Cancel booking now'
  },
  CANCEL_BOOKING: {
    de: 'Ihre Buchung stornieren',
    en: 'Cancel your booking'
  },
  MY_EVENTS: {
    de: 'Meine Veranstaltungen'
  },
  UNBOOK: {
    de: 'Buchung stornieren'
  },
  BOOKABLE_EVENTS: {
    de: 'Buchbare Veranstaltungen',
    en: 'Available events'
  },
  YOU_HAVE_NO_BOOKED_EVENTS: {
    de: 'Sie haben keine Veranstaltungen gebucht',
    en: 'You havn\'t booked any events'
  },
  TOO_LATE_TO_CANCEL_TITLE: {
    de: 'Stornierung nicht möglich',
    en: 'Canceling not possible'
  },
  BOOKING_NOT_POSSIBLE: {
    de: 'Buchung nicht mehr möglich',
    en: 'Canceling not possible anymore'
  },
  TOO_LATE_TO_CANCEL_TITLE_MESSAGE: {
    de: 'Sie können die Buchung nicht mehr stornieren, da der der geplante Veranstaltungsbeginn bereits in der Vergangenheit liegt.',
    en: 'You cannot cancel the booking anymore because according the to the schedule this event has already started.'
  },
  ALREADY_BOOKED_OUT: {
    de: 'Veranstaltung bereits ausgebucht',
    en: 'Event already booked out'
  },
  E_EVENT_OVERBOOKED: {
    de: 'Die Veranstaltung ist bereits voll gebucht. Bitte wählen Sie ggf. einen anderen Termin.',
    en: 'The event is already booked out. Please choose another date if applicable.'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;
var addStrings = _strings.addStrings;
exports.addStrings = addStrings;