"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getStyles = void 0;

var _ColorHelper = require("../../../../helpers/ColorHelper");

var _ObjectHelper = require("../../../../helpers/ObjectHelper");

var getStyles = function getStyles(componentState, componentProps) {
  var theme = null;

  if (componentState.quiz && componentState.currentTheme in componentState.quiz) {
    theme = componentState.quiz[componentState.currentTheme];
  }

  var outerStyle = {};

  if (componentProps && componentProps.hidden) {
    outerStyle.display = 'none';
  }

  var defaultTheme = componentState.quiz ? componentState.quiz["default"] : null;
  var textStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'mainTextColor', defaultTheme, 'mainTextColor', _ColorHelper.COLOR.BLACK)
  };
  var userPointsStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'highScoreUserColor', defaultTheme, 'highScoreUserColor', _ColorHelper.COLOR.BLACK)
  };
  var opponentPointsStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'highScoreOpponentColor', defaultTheme, 'highScoreOpponentColor', _ColorHelper.COLOR.BLACK)
  };
  return {
    textStyle: textStyle,
    userPointsStyle: userPointsStyle,
    opponentPointsStyle: opponentPointsStyle,
    outerStyle: outerStyle
  };
};

exports.getStyles = getStyles;