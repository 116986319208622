"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../../config/strings");

var STRINGS = {
  PUSH_NOT_SENT: {
    de: 'Deine Nachricht konnte nicht versandt werden.'
  },
  PUSH_ERROR: {
    de: 'Nachricht nicht versandt'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;