"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.FEATURE_NAME = void 0;
var FEATURE_NAME = 'drupal_tiles';
exports.FEATURE_NAME = FEATURE_NAME;