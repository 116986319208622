"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.addStrings = exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  FINISHED_TEXT: {
    de: 'Vielen Dank, dass Sie sich die Zeit genommen haben.<br /><br />',
    en: 'You have just finished the introduction. Thank you for taking the time to answer the questions and provide your details. We look forward to a productive partnership with you.<br /><br />',
    it: 'Hai completato con successo la parte introduttiva. Grazie per il tempo che ci hai dedicato e per tutte le informazioni che ci hai fornito.<br /><br />',
    fr: 'Vous venez de terminer l\'introduction. Nous vous remercions d\'avoir pris le temps de répondre aux questions et renseigné vos coordonnées. Nous espérons un partenariat fructueux avec vous...',
    es: 'Usted ha terminado la introducción. Gracias por su tiempo para responder las preguntas y hacernos llegar los comentarios. Esperamos comenzar a hacer negocios pronto con usted.<br /><br />'
  },
  FINISHED_TEXT2: {
    de: 'Sie werden nun zum <a id="snom_referrer" href="$0" target="_parent">Partnerportal</a> zurück geleitet.',
    en: 'You will now be redirected to the <a id="snom_referrer" href="$0" target="_parent">partner portal website</a>.',
    it: 'Ora verrai reindirizzato al <a id="snom_referrer" href="$0" target="_parent">portale dedicato ai Partner</a>.',
    fr: 'Vous allez être redirigé vers le site du <a id="snom_referrer" href="$0" target="_parent">portail partenaire</a>.',
    es: 'Ahora se lo redireccionará al <a id="snom_referrer" href="$0" target="_parent">sitio web del portal de socios</a>.'
  },
  FINISHED_TEXT2B: {
    de: 'Sie können nun zum <a href="$0" target="_parent">Partnerportal</a> zurückkehren.',
    en: 'You can return to the <a href="$0" target="_parent">partner portal website</a>.',
    it: '',
    fr: '',
    es: ''
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;
var addStrings = _strings.addStrings;
exports.addStrings = addStrings;