"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../../config/strings");

var STRINGS = {
  IFRAME_CONTENT_NOT_AVAILABLE_TITLE: {
    de: 'Inhalt nicht verfügbar',
    en: 'Content not available'
  },
  IFRAME_CONTENT_NOT_AVAILABLE_MESSAGE: {
    de: 'Es konnte keine Verbindung zum Server hergestellt werden. Bitte überprüfe Deine Internetverbindung oder probiere es später noch einmal.',
    en: 'Sorry, we could not establish a connection to the server. Please check your internet connection or try again later.'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;