"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../../config/strings");

var STRINGS = {
  DEVICE_LOCKED: {
    de: 'Gerät gesperrt.'
  },
  DEVICE_LOCKED_EXPLANATION: {
    de: 'Du bist jetzt auf einem anderen Gerät angemeldet. Wenn du auf diesem Gerät weiterspielen willst, melde Dich bitte erneut an.'
  },
  PLAYER_EMAIL: {
    de: 'E-Mail-Adresse'
  },
  PLAYER_NICKNAME: {
    de: 'Spielername'
  },
  PLAYER_PASSWORD_EMAIL_EXPLANATION: {
    de: 'Prima!<br/>Du möchtest als <b>$0</b> spielen.<br/>Bitte leg ein Passwort fest und gib deine E-Mailadresse für die Passwortwiederherstellung an.'
  },
  PLAYER_NICKNAME_EXPLANATION: {
    de: 'Gib Dir einen Spielernamen, um in der Gruppe <b>$0</b> zu spielen.'
  },
  GROUP_CODE: {
    // de: 'Gruppencode'
    de: 'Gruppenname'
  },
  ENTER_GROUP: {
    de: 'Spielgruppe beitreten'
  },
  ENTER_GROUP_EXPLANATION: {
    // de: 'Hallo beim Quizspiel!<br/>Bitte gib deinen Gruppencode ein.'
    de: 'Hallo beim Quizspiel!<br/>Bitte gib deinen Gruppennamen ein.'
  },
  GROUP_CODE_WRONG: {
    // de: 'Dieser Gruppencode ist unbekannt.</br>Versuch es noch mal.'
    de: 'Dieser Gruppenname ist unbekannt.</br>Versuch es noch mal.'
  },
  ENTER_GROUP_EMPTY: {
    // de: 'Bitte gib deinen Gruppencode ein.'
    de: 'Bitte gib deinen Gruppennamen ein.'
  },
  LOGIN_EXPLANATION: {
    de: 'Diesen Spieler gibt es bereits. Bitte gib das Passwort ein oder registriere dich mit einem anderen Namen.'
  },
  LOGIN_EXPLANATION_ERGO: {
    de: 'Hallo $0<br />Bitte geben Sie Ihr Passwort ein.'
  },
  PIN_LOCKED_EXPLANATION: {
    de: 'Hallo <b>$0</b>!<br />Du hast ein Passwort Reset angefordert. Bitte schaue in deine E-Mails.'
  },
  REGISTER_EXPLANATION_ERGO: {
    de: 'Willkommen $0<br/>Bitte legen Sie Ihr Passwort fest.'
  },
  REGISTER_EXPLANATION: {
    de: 'Prima!<br/>Du möchtest als <b>$0</b> spielen.<br/>Bitte leg ein Passwort fest.'
  },
  RESET_TITLE: {
    de: 'Passwort neu setzen'
  },
  RESET_EXPLANATION: {
    de: 'Dein Spielleiter hat dein Passwort zurückgesetzt. Du kannst jetzt ein neues Passwort vergeben.'
  },
  ERGO_RESET_EXPLANATION: {
    de: 'Sie haben Ihr Passwort erfolgreich zurückgesetzt. Sie können jetzt ein neues Passwort vergeben.'
  },
  PASSWORD_RESET_EXPLANATION: {
    de: 'Hallo $0,<br/>wir haben deinem Spielleiter eine Mail geschickt. Er kann dein Passwort nun zurücksetzen.'
  },
  PASSWORD_MAIL_RESET_EXPLANATION: {
    de: 'Hallo $0,<br/>wir haben dir eine E-Mail geschickt. Bitte schaue in dein Postfach, um dein Passwort zurückzusetzen.'
  },
  ERGO_PASSWORD_RESET_EXPLANATION: {
    de: 'Hallo $0,<br/>wir haben Ihnen eine E-Mail geschickt. Bitte schauen Sie in Ihr Postfach, um Ihr Passwort zurückzusetzen.'
  },
  RESET_ERROR: {
    de: 'Es gab einen Fehler beim Absenden des Requests. Bitte versuch es erneut.'
  },
  E_PASSWORDS_EMPTY: {
    de: 'Bitte füllen Sie die Passwortfelder aus.'
  },
  OFFLINE_LOAD: {
    de: 'Du bist nicht online.<br/>Deine Daten können nicht geladen werden. Bitte gehe online und versuch es nochmal.'
  },
  ENTER_PERSONAL_NUMBER_EXPLANATION: {
    de: 'Herzlich Willkommen<br/>Bitte geben Sie Ihre 6-stellige Personalnummer ohne den führenden Buchstaben ein.'
  },
  ENTER_PERSONAL_NUMBER: {
    de: 'Spielgruppe beitreten'
  },
  PERSONAL_NUMBER: {
    de: 'Personalnummer'
  },
  PERSONAL_NUMBER_NO_DATA: {
    de: 'Zu dieser Personalnummer sind keine Daten hinterlegt. Bitte wenden Sie sich an den Support.'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;