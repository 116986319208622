"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.LARGEST_TILE_SIZE = exports.MARGIN_BETWEEN_TILES = exports.LANDSCAPE_FULL = exports.FULL_SIZE_TILE_SIZE = exports.TILE_SIZES = void 0;

var _values = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/object/values"));

/**
 * The conditions for the values are:
 *  1. LARGE is 250px wide
 *  2. Two SMALL blocks plus their margin in between (10px each) are as wide as one LARGE
 *  3. Two MEDIUM blocks plus their margin are as wide as one LARGE plus one SMALL plus their margin
 *  4. Two RESET blocks plus their margin are as wide as one MEDIUM block
 *
 * @type {{RESET: number, SMALL: number, MEDIUM: number, LARGE: number}}
 */
var TILE_SIZES = {
  INVISIBLE: 0,
  RESET: 81.25,
  SMALL: 115,
  MEDIUM: 182.5,
  LARGE: 250
};
exports.TILE_SIZES = TILE_SIZES;
var FULL_SIZE_TILE_SIZE = 790;
exports.FULL_SIZE_TILE_SIZE = FULL_SIZE_TILE_SIZE;
var LANDSCAPE_FULL = 'landscape_full';
exports.LANDSCAPE_FULL = LANDSCAPE_FULL;
var MARGIN_BETWEEN_TILES = 10; // px

exports.MARGIN_BETWEEN_TILES = MARGIN_BETWEEN_TILES;
var LARGEST_TILE_SIZE = Math.max.apply(null, (0, _values["default"])(TILE_SIZES));
exports.LARGEST_TILE_SIZE = LARGEST_TILE_SIZE;