"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = exports.MockCourse = void 0;
var MockCourse = {};
exports.MockCourse = MockCourse;

var _default = function _default(resolve, reject, pluginName, methodName, args) {
  console.error('mock must not be called in production mode or no download folder with mocked data was present at compile time');

  if (reject) {
    reject();
  }
};

exports["default"] = _default;