"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.MONTHLY_BACKGROUND = exports.mockedPositions = exports.mockedTargets = void 0;
var STRUCTURE_TARGET_TYPES = {
  OWN: 'own',
  OTHERS: 'others',
  BOTH: 'both'
};
var TARGET_TYPES = {
  OTHER: 'userChosen',
  LEVEL: 'userLevel',
  ADD: 'addTarget'
};
var mockedTargets = [{
  name: 'Montblanc Meisterstück Collegemappe',
  background: require('./Montblanc_Collegemappe.jpg'),
  conditions: [{
    description: 'Eigeneinheiten',
    value: 500,
    time: null,
    type: STRUCTURE_TARGET_TYPES.OWN
  }],
  info: ['500 Eigeneinheiten (NG) in zwei aufeinander folgenden Produktionsmonaten', 'Hier zählt die Eigen-QG18 Quote < 12 %']
}, {
  name: 'ERGO Pro Nadel in Gold',
  background: require('./Nadel_Gold.jpg'),
  conditions: [{
    description: 'Eigeneinheiten',
    value: 500,
    time: null,
    type: STRUCTURE_TARGET_TYPES.OWN
  }],
  info: ['500 Eigeneinheiten (NG) mit mind. 10 Verträgen innerhalb von 30 Tagen und lfd. Beitragszahlung oder', '20 Verträge innerhalb von 30 Tagen und lfd. Beitragszahlung']
}, {
  name: 'ERGO Pro Nadel in Gold mit Brilliant',
  background: require('./Nadel_Gold.jpg'),
  conditions: [{
    description: 'Eigeneinheiten',
    value: 800,
    time: null,
    type: STRUCTURE_TARGET_TYPES.OWN
  }],
  info: ['800 Eigeneinheiten mit mind. 10 Verträgen innerhalb von 30 Tagen und lfd. Beitragszahlung', 'Alternativ 30 Verträge innerhalb von 30 Tagen und lfd. Beitragszahlung.\n' + 'Erfüllt ein ERGO Pro Geschäftspartner die Kriterien erneut und hat die Nadel schon, erhält er stattdessen zwei Brillanten auf die bereits vorhandene Nadel.']
}, {
  type: TARGET_TYPES.LEVEL,
  structureUnitFactor: 0.7,
  value: 2700,
  name: 'ERGO Pro Nadel in Platin',
  background: require('./Nadel_Platin.jpg'),
  conditions: [{
    description: 'Eigen- und Fremdeinheiten',
    time: null,
    value: 2700,
    type: STRUCTURE_TARGET_TYPES.BOTH
  }],
  info: ['Rekrutierung von 6 neuen direkten ERGO Pro Partnern mit je 66 Einheiten(NG)in 6 Monaten rollierend.\n Hier zählt die QG18 Strukturstornoquote < 12 %', 'Für das Erreichen der Stufe 3 innerhalb des ersten persönlichen Halbjahres nach den regulären Stufenschreibungskriterien']
}, {
  type: TARGET_TYPES.LEVEL,
  structureUnitFactor: 0.7,
  value: 2700,
  name: 'Montblanc Generation Kugelschreiber',
  background: require('./Kugelschreiber.jpg'),
  conditions: [{
    description: 'Eigen- und Fremdeinheiten',
    time: null,
    value: 2700,
    type: STRUCTURE_TARGET_TYPES.BOTH
  }],
  info: ['Erreichen der Stufe 3 nach den regulären Stufenschreibungskriterien']
}, {
  type: TARGET_TYPES.LEVEL,
  structureUnitFactor: 0.5,
  value: 7000,
  name: 'Chopard Mille Miglia Set für Damen',
  background: require('./Chopard.jpg'),
  conditions: [{
    description: 'Eigen- und Fremdeinheiten',
    time: null,
    value: 7000,
    type: STRUCTURE_TARGET_TYPES.BOTH
  }],
  info: ['Erreichen der Stufe 4 nach den regulären Stufenschreibungskriterien und Bestätigung der jeweiligen Stufe im Prüfungszeitraum']
}, {
  type: TARGET_TYPES.LEVEL,
  structureUnitFactor: 0.5,
  value: 7000,
  name: 'Chopard Mille Miglia Set für Herren',
  background: require('./Uhr.jpg'),
  conditions: [{
    description: 'Eigen- und Fremdeinheiten',
    time: null,
    value: 7000,
    type: STRUCTURE_TARGET_TYPES.BOTH
  }],
  info: ['Erreichen der Stufe 4 nach den regulären Stufenschreibungskriterien und Bestätigung der jeweiligen Stufe im Prüfungszeitraum']
}, {
  type: TARGET_TYPES.LEVEL,
  structureUnitFactor: 0.5,
  value: 20000,
  name: 'Montblanc Meisterstück Füllfederhalter',
  background: require('./Montblanc_Füllfederhalter.jpg'),
  conditions: [{
    description: 'Eigen- und Fremdeinheiten',
    time: null,
    value: 20000,
    type: STRUCTURE_TARGET_TYPES.BOTH
  }],
  info: ['Erreichen der Stufe 5 nach den regulären Stufenschreibungskriterien und Bestätigung der jeweiligen Stufe im Prüfungszeitraum']
}, {
  type: TARGET_TYPES.LEVEL,
  structureUnitFactor: 0.5,
  value: 50000,
  name: 'Montblanc Meisterstück Aktentasche',
  background: require('./Montblanc_Aktentasche.jpg'),
  conditions: [{
    description: 'Eigen- und Fremdeinheiten',
    time: null,
    value: 50000,
    type: STRUCTURE_TARGET_TYPES.BOTH
  }],
  info: ['Erreichen der Stufe 6 nach den regulären Stufenschreibungskriterien und Bestätigung der jeweiligen Stufe im Prüfungszeitraum']
}];
exports.mockedTargets = mockedTargets;
var mockedPositions = [{
  type: TARGET_TYPES.LEVEL,
  name: 'Stufe 2 - Leitender Repräsentant',
  background: require('./ERGO_bg.png'),
  level: 2,
  structureUnitFactor: 1.0,
  value: 500,
  conditions: [{
    description: '',
    time: null,
    value: 500,
    type: STRUCTURE_TARGET_TYPES.OWN
  }, {
    description: '',
    time: null,
    value: 500,
    type: STRUCTURE_TARGET_TYPES.OTHERS
  }]
}, {
  type: TARGET_TYPES.LEVEL,
  name: 'Stufe 3 - Hauptrepräsentant',
  background: require('./Kugelschreiber.jpg'),
  level: 3,
  structureUnitFactor: 0.7,
  value: 2700,
  conditions: [{
    description: '',
    time: null,
    value: 2700,
    type: STRUCTURE_TARGET_TYPES.BOTH
  }]
}, {
  type: TARGET_TYPES.LEVEL,
  name: 'Stufe 4 - Chefrepräsentant',
  background: require('./Uhr.jpg'),
  level: 4,
  structureUnitFactor: 0.5,
  value: 7000,
  conditions: [{
    description: '',
    time: null,
    value: 7000,
    type: STRUCTURE_TARGET_TYPES.BOTH
  }]
}, {
  type: TARGET_TYPES.LEVEL,
  name: 'Stufe 5 - Direktionsrepräsentant der Stufe 5',
  background: require('./Montblanc_Füllfederhalter.jpg'),
  level: 5,
  structureUnitFactor: 0.5,
  value: 20000,
  conditions: [{
    description: '',
    time: null,
    value: 20000,
    type: STRUCTURE_TARGET_TYPES.BOTH
  }]
}, {
  type: TARGET_TYPES.LEVEL,
  name: 'Stufe 6 - Direktionsrepräsentant der Stufe 6',
  background: require('./Montblanc_Aktentasche.jpg'),
  level: 6,
  structureUnitFactor: 0.5,
  value: 50000,
  conditions: [{
    description: '',
    time: null,
    value: 50000,
    type: STRUCTURE_TARGET_TYPES.BOTH
  }]
}];
exports.mockedPositions = mockedPositions;

var MONTHLY_BACKGROUND = require('./ERGO_bg.png');

exports.MONTHLY_BACKGROUND = MONTHLY_BACKGROUND;