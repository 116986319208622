"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.addOnResumeListener = addOnResumeListener;
exports.removeOnResumeListener = removeOnResumeListener;
exports.addOnSuspendListener = addOnSuspendListener;
exports.removeOnSuspendListener = removeOnSuspendListener;

var _ArrayHelper = require("./ArrayHelper");

/**
 * Why this file?
 * In Javascript+Cordova you can simply use document.addEventLister('pause', fn) and document.addEventLister('resume', fn).
 *
 * Sadly, the latter doesn't work if the app is opened by a deeplink.
 *
 * The method addOnResumeListener here, circumvents this problem by calling the listeners even if resumed by deeplink.
 *
 * All other methods are only for your convenience.
 */
var resumeListener = [];
var suspendListener = [];

function addOnResumeListener(listener) {
  if (!resumeListener.includes(listener)) {
    resumeListener.push(listener);
  }
}

function removeOnResumeListener(listener) {
  (0, _ArrayHelper.removeElement)(resumeListener, listener);
}

function addOnSuspendListener(listener) {
  if (!suspendListener.includes(listener)) {
    suspendListener.push(listener);
  }
}

function removeOnSuspendListener(listener) {
  (0, _ArrayHelper.removeElement)(suspendListener, listener);
}

document.addEventListener('resume', function () {
  console.log('[global] app resumed');
  resumeListener.forEach(function (listener) {
    return listener();
  });
});
document.addEventListener('pause', function () {
  console.log('[global] app suspend');
  suspendListener.forEach(function (listener) {
    return listener();
  });
});