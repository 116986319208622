"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../../config/strings");

var STRINGS = {
  CHAT_FROM_GALLERY: {
    de: 'Galerie'
  },
  CHAT_FROM_CAMERA: {
    de: 'Kamera'
  },
  CHAT_CHOOSE_IMAGE_SOURCE_TEXT: {
    de: 'Wählen die Quelle für das Bild, was Sie versenden wollen:'
  },
  CHAT_CHOOSE_IMAGE_SOURCE_TITLE: {
    de: 'Bildquelle wählen'
  },
  CAND_FROM_GALLERY: {
    de: 'Galerie'
  },
  CAND_FROM_CAMERA: {
    de: 'Kamera'
  },
  CAND_OTHER_CHOOSE_IMAGE_SOURCE_TEXT: {
    de: 'Bitte wählen Sie die Quelle für Ihr Foto'
  },
  CAND_OTHER_CHOOSE_IMAGE_SOURCE_TITLE: {
    de: 'Neues Profilbild'
  },
  CAND_USER_CHOOSE_IMAGE_SOURCE_TEXT: {
    de: 'Bitte wählen Sie die Quelle für das Foto'
  },
  CAND_USER_CHOOSE_IMAGE_SOURCE_TITLE: {
    de: 'Neues Profilbild'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;