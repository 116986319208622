"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../config/strings");

var STRINGS = {
  PUSH_NOTIFICATION_ERROR: {
    de: 'Push-Fehler',
    en: 'Push Notification Error'
  },
  PUSH_NOTIFICATION_ERROR_MSG: {
    de: 'Das FCM-Plugin konnte nicht gefunden werden, daher ist der Empfang von Pushnachrichten nicht möglich'
  },
  QUIT_APP: {
    de: 'App beenden',
    en: 'Quit app'
  },
  TC_PRIVACY_ACCEPT: {
    de: 'Akzeptieren',
    en: 'Accept',
    it: 'Accettare',
    cs: 'Akceptovat',
    pl: 'Zaakceptować',
    es: 'Aceptar',
    zh: '接受',
    sv: 'Acceptera',
    da: 'Acceptere'
  },
  REACT: {
    de: 'Reagieren'
  },
  MUST_LOG_IN: {
    de: 'Du hast Dich auf einem anderen Gerät angemeldet. Bitte melde Dich hier neu an, um hier weiterzuarbeiten.'
  },
  AUTOMATIC_LOGOUT: {
    de: 'Automatische Abmeldung'
  },
  CONTENT_UPDATED_SNACKBAR: {
    de: 'Der Inhalt wurde aktualisiert',
    en: 'The content was updated'
  },
  LOOKING_FOR_UPDATES: {
    de: 'Suche nach Updates...',
    en: 'Looking for updates...'
  },
  ALREADY_LOOKING_FOR_UPDATES: {
    de: 'Suche nach Updates läuft bereits',
    en: 'Already looking for updates'
  },
  CONTENT_IS_UPDATING: {
    de: 'Der Inhalt wird aktualisiert...',
    en: 'The content is updating'
  },
  UPDATE_NOW: {
    de: 'Jetzt laden',
    en: 'Update now'
  },
  UPDATE_LATER: {
    de: 'Später',
    en: 'Later'
  },
  UPDATE_AVAILABLE_TITLE: {
    de: 'Update verfügbar',
    en: 'Update available'
  },
  UPDATE_AVAILABLE: {
    de: 'Es gibt ein Update für den Inhalt Ihrer App. Soll das Update jetzt geladen werden?',
    en: 'There is a content update for your app. Would you like to update?'
  },
  NO_UPDATE_AVAILABLE_SNACKBAR: {
    de: 'Der Inhalt ist auf dem aktuellen Stand',
    en: 'There is no update available, you already have the latest content'
  },
  GO_ONLINE_TO_REGISTER_AUTOMATICALLY: {
    de: 'Bitte stelle eine Internetverbindung her, um die automatische Registrierung durchzuführen.'
  },
  GET_DATA_FROM_SERVER_TITLE: {
    // de: 'Einen kleinen Moment noch'
    de: 'Nur einen kurzen Moment Geduld'
  },
  GET_DATA_FROM_SERVER_MESSAGE: {
    de: 'Es werden noch schnell ein paar Daten vom Server abgerufen...'
  },
  AUTOMATIC_LOGIN_TITLE: {
    de: 'Automatischer Login'
  },
  AUTOMATIC_LOGIN_MESSAGE: {
    de: 'Der automatische Login ist gleich fertig...'
  },
  E_CONFIG_MESSAGES_NOT_FOUND: {
    de: 'Die gesuchten Nachrichten sind auf dem Server nicht verfügbar.'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;