"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../../../config/strings");

var _react = _interopRequireDefault(require("react"));

var STRINGS = {
  VOTE_NOW: {
    de: 'ABSTIMMEN'
  },
  HIDE_VOTE_RESULTS: {
    de: 'Danke für Ihre Stimme.'
  },
  MIDDLEVALUE: {
    de: 'Mittelwert'
  },
  EVALUATION: {
    de: 'Auswertung'
  },
  TOTAL_VOTES: {
    de: 'Anzahl Abstimmungen'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;