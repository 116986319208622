"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../config/strings");

var STRINGS = {
  ENTER_TOKEN: {
    de: 'Bitte geben Sie Ihren Aktivierungscode ein'
  },
  ENTER_TOKEN_MOBILE: {
    de: 'Bitte geben Sie Ihren Aktivierungscode ein oder scannen Sie den QR Code mit Hilfe des QR Code Scanners in den Einstellungen'
  },
  ENTER_TOKEN_TITLE: {
    de: 'Kurs aktivieren'
  },
  ENTER_TOKEN_ERROR: {
    de: 'Bitte geben Sie einen Aktivierungscode ein'
  },
  ENTER_TOKEN_NO_MATCH: {
    de: 'Der eingegebene Aktivierungscode ist leider falsch. Bitte versuchen Sie es erneut.'
  },
  WRONG_USER: {
    de: 'Der zugehörige QR Code ist nicht für Ihren Nutzer bestimmt'
  },
  WRONG_USER_TITLE: {
    de: 'Falscher Code'
  },
  ENTER_TOKEN_SUCCES: {
    de: 'Der Kurs wurde erfolgreich freigeschaltet'
  },
  ENTER_TOKEN_SUCCESS_TITLE: {
    de: 'Freischaltung erfolgreich'
  },
  ENTER_TOKEN_ERROR_NO_MATCH: {
    de: 'Der eingegebene Aktivierungscode ist nicht korrekt. Bitte versuchen Sie es erneut.'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;