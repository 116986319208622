"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getZoomTransform = getZoomTransform;
exports.focusElement = focusElement;
exports.normalizeInput = normalizeInput;

function getZoomTransform(zoomDivPosition, isQuestion) {
  var divWidth = zoomDivPosition.right - zoomDivPosition.left;
  var divCenterHorizontally = divWidth / 2 + zoomDivPosition.left;
  var divHeight = zoomDivPosition.bottom - zoomDivPosition.top;
  var divCenterVertically = divHeight / 2 + zoomDivPosition.top;
  var scale = window.innerWidth * 0.8 / divWidth;
  var transform = "scale(".concat(scale, ") translate3d(").concat((window.innerWidth * 0.5 - divCenterHorizontally) / scale, "px, ").concat((isQuestion ? zoomDivPosition.top - 10 : window.innerHeight * 0.5 - divCenterVertically) / scale, "px, 0)");
  console.log('getZoomTransform', transform);
  return [scale, transform];
}

function focusElement(id) {
  var el = document.getElementById(id);
  console.log('focus input', el);

  if (el) {
    el.focus();
  } else {
    console.log('could not find input to focus');
  }
}

function normalizeInput(userInput) {
  return userInput.trim().replace(/^\\w\\d/g, ' ').replace(/\s{2,}/g, ' ');
}