"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _CordovaHelper = require("../../../../helpers/CordovaHelper");

var LOG_CONTEXT = 'equeoqd';
var _default = {
  postQuestionSolvedLog: function postQuestionSolvedLog(userId, gameId, questionData, answerId, timeTaken) {
    var type = 'QuestionSolvedLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userId: userId,
      gameId: gameId,
      round: questionData.round,
      questionId: questionData.id || questionData.number,
      category: questionData.category,
      timeTaken: timeTaken,
      answerId: answerId,
      action: 'answered'
    });
  },
  postQuestionTimeoutLog: function postQuestionTimeoutLog(userId, gameId, questionData) {
    var type = 'QuestionTimeoutLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userId: userId,
      gameId: gameId,
      round: questionData.round,
      questionId: questionData.id || questionData.number,
      category: questionData.category,
      action: 'question timeout'
    });
  },
  postHighScoreScreenViewedLog: function postHighScoreScreenViewedLog(userId) {
    var type = 'HighScoreScreenViewedLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userId: userId,
      action: 'highscore viewed'
    });
  },
  postLeagueHighScoreScreenViewedLog: function postLeagueHighScoreScreenViewedLog(userId) {
    var type = 'LeagueHighScoreScreenViewedLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userId: userId,
      action: 'league highscore viewed'
    });
  },
  postProfilePicLog: function postProfilePicLog(userId, option) {
    var type = 'ProfilePicLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userId: userId,
      usedOption: option,
      action: 'change pro pic'
    });
  },
  postSendCustomPushLog: function postSendCustomPushLog(userId, opponent) {
    var type = 'SendCustomPushLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userId: userId,
      opponent: opponent,
      action: 'custom push'
    });
  },
  postSelfEvaluationResponseLog: function postSelfEvaluationResponseLog(userId, gameId, questionData, duration, answer, userScore) {
    var type = 'SelfEvaluationResponseLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userId: userId,
      gameId: gameId,
      round: questionData.round,
      questionId: questionData.id || questionData.number,
      category: questionData.category,
      readDuration: duration.read || 0,
      answerDuration: duration.answer,
      feedbackDuration: duration.feedback,
      feedback: questionData.feedback ? 1 : 0,
      answer: answer,
      userScore: userScore,
      action: 'self evaluation response'
    });
  },
  postQuestionResponseLog: function postQuestionResponseLog(userId, gameId, questionData, duration, result) {
    var type = 'QuestionResponseLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userId: userId,
      gameId: gameId,
      round: questionData.round,
      questionId: questionData.id || questionData.number,
      category: questionData.category,
      readDuration: duration.read || 0,
      answerDuration: duration.answer,
      feedbackDuration: duration.feedback,
      feedback: questionData.feedback ? 1 : 0,
      result: result,
      action: 'question response'
    });
  },
  postGameCompletionLog: function postGameCompletionLog(group, userId, opponent, gameId, startTime, endTime) {
    var type = 'GameCompletionLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      group: group,
      userId: userId,
      opponent: opponent,
      gameId: gameId,
      started: startTime,
      completed: endTime,
      action: 'record game completion time'
    });
  },
  postRoundCompletionLog: function postRoundCompletionLog(userId, opponent, gameId, questions, startTime, endTime) {
    var type = 'RoundCompletionLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userId: userId,
      opponent: opponent,
      gameId: gameId,
      questions: questions,
      started: startTime,
      completed: endTime,
      action: 'record round completion time'
    });
  }
};
exports["default"] = _default;