"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _reflux = _interopRequireDefault(require("reflux"));

/**
 * Created by falknisius on 12.06.17.
 */
var logActions = _reflux["default"].createActions({
  logMessageEnqueueAction: {
    actionName: 'logMessageEnqueueAction',
    sync: false
  },
  logMessageDequeueAction: {
    actionName: 'logMessageDequeueAction',
    sync: false
  }
});

var _default = logActions;
exports["default"] = _default;