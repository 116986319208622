"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getString = void 0;

var _strings = require("../../config/strings");

var STRINGS = {
  ASK_USER_FOR_REVIEW_TITLE: {
    de: 'App-Bewertung',
    en: 'App rating'
  },
  ASK_USER_FOR_REVIEW_MESSAGE: {
    de: 'Gefällt dir diese App? Hast du Lust, sie zu bewerten?',
    en: 'Do you like this app? Would you mind to rate it?'
  },
  ASK_USER_FOR_REVIEW_POSITIVE_BUTTON: {
    de: 'Klar, gerne',
    en: 'Sure'
  },
  ASK_USER_FOR_REVIEW_NEGATIVE_BUTTON: {
    de: 'Nein, jetzt nicht',
    en: 'No, not now'
  }
};

function addStringsToGlobalList() {
  if (!_strings.addStrings) {
    console.log('addStrings is still null');
    setTimeout(addStringsToGlobalList, 50);
    return;
  } // console.log('addstrings is possible now')


  (0, _strings.addStrings)(STRINGS);
}

addStringsToGlobalList(); // named export for better import proposals by webstorm

var getString = function getString() {
  return _strings.getString.apply(void 0, arguments);
};

exports.getString = getString;