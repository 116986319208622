"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.ACTIVITY_TAGS = void 0;
var ACTIVITY_TAGS = {
  UNITS_BOOKED: 'units booked',
  UNITS_DELETED: 'units deleted',
  TARGET_SET: 'target set',
  TARGET_CHANGED: 'target changed',
  PERSONAL_TARGET_REACHED: 'own target reached',
  STRUCTURE_TARGET_REACHED: 'other target reached',
  PICTURE_CHANGED: 'picture changed',
  SUPER_TRANSACTION: 'super transaction',
  SLOW_TRANSACTIONS: 'slow transactions',
  NO_TRANSACTIONS: 'no transactions',
  MANY_CONTRACTS: 'many contracts',
  LITTLE_CONTRACTS_STRUCTURE: 'little contracts structure',
  ENTERED_STRUCTURE: 'entered structure'
};
exports.ACTIVITY_TAGS = ACTIVITY_TAGS;