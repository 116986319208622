"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.BUILT_FOR_BROWSER = exports.FOR_PROD_SERVER = exports.IS_KESI_APP = exports.CORONA_HOST = exports.CHAT_HOST = exports.RECRUIT_HOST = exports.LEARNING_STATE_HOST = exports.MAIL_HOST = exports.SEMINARS_HOST = exports.QUIZZER_HOST = exports.MAP_SERVER = exports.MAP_HOST = exports.REGION_IMAGE_HOST = exports.POLICY_HOST = exports.NODE_ENV = exports.PDF_HOST = exports.LEDGER_HOST = exports.AUTH_HOST = exports.ABLY_CHANNEL_PREFIX = exports.PING_URL = exports.ECLOUD_HOST = exports.BUILD_TIMESTAMP = exports.HOST = void 0;
var HOST = 'https://ems3.equeo.de/ems-webapp';
exports.HOST = HOST;
var BUILD_TIMESTAMP = '2022-10-25T07:02:28.967Z';
exports.BUILD_TIMESTAMP = BUILD_TIMESTAMP;
var ECLOUD_HOST = 'https://eurofh-ecloud.equeo.de';
exports.ECLOUD_HOST = ECLOUD_HOST;
var PING_URL = 'undefined';
exports.PING_URL = PING_URL;
var ABLY_CHANNEL_PREFIX = 'private:eurofh:';
exports.ABLY_CHANNEL_PREFIX = ABLY_CHANNEL_PREFIX;
var AUTH_HOST = 'https://eurofh-auth.equeo.de';
exports.AUTH_HOST = AUTH_HOST;
var LEDGER_HOST = 'https://eurofh-ledger.equeo.de';
exports.LEDGER_HOST = LEDGER_HOST;
var PDF_HOST = 'https://pdf-prod.equeo.de';
exports.PDF_HOST = PDF_HOST;
var NODE_ENV = 'debug';
exports.NODE_ENV = NODE_ENV;
var POLICY_HOST = 'undefined';
exports.POLICY_HOST = POLICY_HOST;
var REGION_IMAGE_HOST = 'undefined';
exports.REGION_IMAGE_HOST = REGION_IMAGE_HOST;
var MAP_HOST = 'undefined';
exports.MAP_HOST = MAP_HOST;
var MAP_SERVER = 'undefined';
exports.MAP_SERVER = MAP_SERVER;
var QUIZZER_HOST = 'https://equeo-quizzer-prod.equeo.de';
exports.QUIZZER_HOST = QUIZZER_HOST;
var SEMINARS_HOST = 'null';
exports.SEMINARS_HOST = SEMINARS_HOST;
var MAIL_HOST = 'https://mailservice-prod.equeo.de';
exports.MAIL_HOST = MAIL_HOST;
var LEARNING_STATE_HOST = 'undefined';
exports.LEARNING_STATE_HOST = LEARNING_STATE_HOST;
var RECRUIT_HOST = 'null';
exports.RECRUIT_HOST = RECRUIT_HOST;
var CHAT_HOST = 'https://equeo-chatservice-prod.equeo.de';
exports.CHAT_HOST = CHAT_HOST;
var CORONA_HOST = 'undefined'; // eslint-disable-next-line no-undef

exports.CORONA_HOST = CORONA_HOST;
var IS_KESI_APP = true; // is a boolean
// eslint-disable-next-line no-undef

exports.IS_KESI_APP = IS_KESI_APP;
var FOR_PROD_SERVER = true; // is a boolean
// eslint-disable-next-line no-undef

exports.FOR_PROD_SERVER = FOR_PROD_SERVER;
var BUILT_FOR_BROWSER = true; // is a boolean

exports.BUILT_FOR_BROWSER = BUILT_FOR_BROWSER;