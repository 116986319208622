"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.CONTENT_TYPES = exports.TOURNAMENT_WARNING_TIMEOUT = exports.SMARTPHONE_WIDTH_PORTRAIT_BREAKPOINT = exports.IFRAME_WHEEL_SCROLL_HEIGHT = exports.NAVBAR_HEIGHT = exports.LANDSCAPE_RATIO = exports.PERFECT_RATIO = exports.SMALL_DEVICE_RATIO = exports.MIN_TABLET_LENGTH = exports.MAIN_MENU_WIDTH = exports.UPDATE_PATH = exports.TOPIC_CHOOSER_PATH = exports.FAVS_PATH = exports.REGISTER_PATH = exports.GLOSSARY_PATH = exports.USER_PATH = exports.SETTINGS_PATH = exports.SMALL_TEXT_DEVICE_WIDTH = exports.GLOBAL_Z_INDEXES = void 0;
var GLOBAL_Z_INDEXES = {
  navbar: 10000,
  menu: 10001
};
exports.GLOBAL_Z_INDEXES = GLOBAL_Z_INDEXES;
var SMALL_TEXT_DEVICE_WIDTH = 400;
exports.SMALL_TEXT_DEVICE_WIDTH = SMALL_TEXT_DEVICE_WIDTH;
var SETTINGS_PATH = 'settings';
exports.SETTINGS_PATH = SETTINGS_PATH;
var USER_PATH = 'user';
exports.USER_PATH = USER_PATH;
var GLOSSARY_PATH = 'glossary';
exports.GLOSSARY_PATH = GLOSSARY_PATH;
var REGISTER_PATH = 'register';
exports.REGISTER_PATH = REGISTER_PATH;
var FAVS_PATH = 'favs';
exports.FAVS_PATH = FAVS_PATH;
var TOPIC_CHOOSER_PATH = 'topicchooser';
exports.TOPIC_CHOOSER_PATH = TOPIC_CHOOSER_PATH;
var UPDATE_PATH = 'update';
exports.UPDATE_PATH = UPDATE_PATH;
var MAIN_MENU_WIDTH = 300;
exports.MAIN_MENU_WIDTH = MAIN_MENU_WIDTH;
var MIN_TABLET_LENGTH = 940;
exports.MIN_TABLET_LENGTH = MIN_TABLET_LENGTH;
var SMALL_DEVICE_RATIO = 1.62;
exports.SMALL_DEVICE_RATIO = SMALL_DEVICE_RATIO;
var PERFECT_RATIO = 1.8;
exports.PERFECT_RATIO = PERFECT_RATIO;
var LANDSCAPE_RATIO = 1.28;
exports.LANDSCAPE_RATIO = LANDSCAPE_RATIO;
var NAVBAR_HEIGHT = 64;
exports.NAVBAR_HEIGHT = NAVBAR_HEIGHT;
var IFRAME_WHEEL_SCROLL_HEIGHT = 80;
exports.IFRAME_WHEEL_SCROLL_HEIGHT = IFRAME_WHEEL_SCROLL_HEIGHT;
var SMARTPHONE_WIDTH_PORTRAIT_BREAKPOINT = 420; //iphone xs max has 414 width

exports.SMARTPHONE_WIDTH_PORTRAIT_BREAKPOINT = SMARTPHONE_WIDTH_PORTRAIT_BREAKPOINT;
var TOURNAMENT_WARNING_TIMEOUT = 5000;
exports.TOURNAMENT_WARNING_TIMEOUT = TOURNAMENT_WARNING_TIMEOUT;
var CONTENT_TYPES = {
  COURSE: 'course',
  SPOC: 'spoc',
  LECTURE: 'lecture',
  NUGGETS: 'nuggets'
};
exports.CONTENT_TYPES = CONTENT_TYPES;