"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.init = init;

var _BuildConstants = require("../../../utils/BuildConstants");

var _UsageActions = _interopRequireDefault(require("./Stores/UsageActions2"));

var _GetFullUsage = _interopRequireDefault(require("./Services/GetFullUsage"));

var _MessagingService = _interopRequireDefault(require("../MessagingService"));

var _ResumeSuspendListeners = require("../../../helpers/ResumeSuspendListeners");

/**
 * Created by falknisius on 15.06.17.
 */
var USAGEINFO_CHANNEL = "".concat(_BuildConstants.ABLY_CHANNEL_PREFIX, "USAGE_SUM");

function init() {
  _MessagingService["default"].registerConnect(function () {
    console.log("channel ".concat(USAGEINFO_CHANNEL, " connected"));
  });

  readFullandListenAfterSuccess();
}

function readFullandListenAfterSuccess() {
  // call GetFullUsage service for initial state
  _GetFullUsage["default"].askForUsageStats().then(function (result) {
    _UsageActions["default"].receiveChangesCompleted(result); // register updates after that


    _MessagingService["default"].registerChannel(USAGEINFO_CHANNEL, _UsageActions["default"].receiveChangesCompleted);
  })["catch"](_UsageActions["default"].receiveChangesFailed);
}

function reconnect() {
  _MessagingService["default"].unregisterChannel(USAGEINFO_CHANNEL, _UsageActions["default"].receiveChangesCompleted);

  readFullandListenAfterSuccess();
} // cordova event


(0, _ResumeSuspendListeners.addOnSuspendListener)(function () {
  _MessagingService["default"].unregisterChannel(USAGEINFO_CHANNEL, _UsageActions["default"].receiveChangesCompleted);
}); // cordova event

(0, _ResumeSuspendListeners.addOnResumeListener)(function () {
  readFullandListenAfterSuccess();
});